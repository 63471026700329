import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Country } from 'src/app/models/country.model';
import { EnrollmentProvince } from 'src/app/models/enrollment-province.model';
import { EnrollmentType } from 'src/app/models/enrollment-type.model';
import { Enrollment } from 'src/app/models/enrollment.model';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-enrollment-dialog',
  templateUrl: './enrollment-dialog.component.html',
  styleUrls: ['./enrollment-dialog.component.css']
})
export class EnrollmentDialogComponent {
  formGroup!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      enrollmentProvinces: EnrollmentProvince[],
      enrollmentTypes: EnrollmentType[],
      countries: Country[],
      enrollment: Enrollment
    },
    public dialogRef: MatDialogRef<EnrollmentDialogComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    private enrollmentService: EnrollmentService,
  ) {
    this.formGroup = this.fb.group({
      country: [this.data.enrollment?.country, [Validators.required]],
      province: [this.data.enrollment?.enrollmentProv, [Validators.required]],
      type: [this.data.enrollment?.enrollmentType, [Validators.required]],
      number: [this.data.enrollment?.number, [Validators.required]],
      expirationDate: [this.data.enrollment?.expirationDate, [Validators.required]],
      file: [this.data.enrollment?.fileS3Key, [Validators.required]],
    });
  }

  async submit(): Promise<void> {
    const enrollment = {
      id: this.data.enrollment.id,
      country: this.country.value?.id !== this.data.enrollment.country?.id ? this.country.value : this.data.enrollment.country,
      enrollmentProv: this.province.value?.id !== this.data.enrollment.enrollmentProv?.id ? this.province.value : this.data.enrollment.enrollmentProv,
      enrollmentType: this.type.value?.id !== this.data.enrollment.enrollmentType?.id ? this.type.value : this.data.enrollment.enrollmentType,
      number: this.number.value !== this.data.enrollment.number ? this.number.value : this.data.enrollment.number,
      doctor: this.data.enrollment.doctor,
      expirationDate: this.expirationDate.value !== this.data.enrollment.expirationDate ? this.expirationDate.value : this.data.enrollment.expirationDate,
      fileS3Key: this.file.value !== this.data.enrollment.fileS3Key ? this.file.value : this.data.enrollment.fileS3Key,
    }

    let duplicatedEnrollments = this.enrollmentService.checkDuplicatedEnrollments(enrollment);
    duplicatedEnrollments = _.filter(duplicatedEnrollments, (el) => enrollment.id !== el.id);
    if(!_.isEmpty(duplicatedEnrollments)) {
      this.toastService.openErrorToast("documentation_enrollment_submit_error_duplicated");
      return;
    }

    this.dialogRef.close(enrollment);
  }

  get country(): FormControl { return this.formGroup.get('country') as FormControl }
  get province(): FormControl { return this.formGroup.get('province') as FormControl }
  get type(): FormControl { return this.formGroup.get('type') as FormControl }
  get number(): FormControl { return this.formGroup.get('number') as FormControl }
  get expirationDate(): FormControl { return this.formGroup.get('expirationDate') as FormControl }
  get file(): FormControl { return this.formGroup.get('file') as FormControl }
}
