import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Optional, Self } from '@angular/core';
import { AbstractControl, Validators } from "@angular/forms";
import { MatTab } from '@angular/material/tabs';
import { Subscription } from "rxjs";
import { startWith } from "rxjs/operators";

@Directive({
  selector: '[isRequired]',
})
export class IsRequiredDirective implements AfterViewInit, OnDestroy {
  @Input('isRequired') abstractControl: AbstractControl | undefined;
  subscription$: Subscription | undefined;

  constructor(
    private elem: ElementRef,
    @Optional() @Self() private matTab: MatTab,
  ) {}

  ngAfterViewInit(): void {
    this.subscription$ = this.abstractControl?.statusChanges
      .pipe(startWith(<string>null))
      .subscribe(() => {
        const validators = this.abstractControl?.hasValidator(
          Validators.required,
        );

        if (validators) {
          if(this.matTab) {
            this.matTab.textLabel = this.addAsterisk(this.matTab.textLabel);
          } else {
            const text = this.elem.nativeElement;
            text.innerText = this.addAsterisk(text.innerText);
          }
        } else {
          if(this.matTab) {
            this.matTab.textLabel = this.removeAsterisk(this.matTab.textLabel);
          } else {
            const text = this.elem.nativeElement;
            text.innerText = this.removeAsterisk(text.innerText);
          }
        }
      });
  }

  addAsterisk(str: string) {
    if (!str.endsWith('*')) {
      return str + '*';
    }
    return str;
  }

  removeAsterisk(str: string) {
    if (str.endsWith('*')) {
      return str.slice(0, -1);
    }
    return str;
  }

  ngOnDestroy(): void {
    this.subscription$ && this.subscription$.unsubscribe();
  }
}
