import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Call } from 'src/app/models/call.model';
import { Cie10 } from 'src/app/models/cie10.model';
import { MedicalHistoryService } from 'src/app/services/medical-history.service';

@Component({
  selector: 'app-medical-record',
  templateUrl: './medical-record.component.html',
  styleUrls: ['./medical-record.component.css'],
})
export class MedicalRecordComponent implements OnInit, OnChanges {
  @Input()
  call!: Call;

  @Input()
  index = 0;

  @Input()
  totalCalls = 0;

  @Input()
  diagnosisList: Cie10[] = [];

  medicalHistory: string;
  diagnosis: string;
  isFamilyMemberCall: boolean;

  constructor(
    private medicalHistoryService: MedicalHistoryService,
  ) { }

  ngOnInit(): void {
    this.getMedicalHistory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.diagnosisList || changes.call) {
      this.getDiagnosisDescription();
    }

    if(changes.call) {
      this.getMedicalHistory();
      this.setIsFamilyMemberCall();
    }
  }

  setIsFamilyMemberCall(): void {
    this.isFamilyMemberCall = (this.call.patient?.id || this.call.patient) !== this.call.caller?.id;
  }

  getMedicalHistory(): void {
    this.medicalHistory = this.medicalHistoryService.getMedicalHistory(this.call?.records);
  }

  getDiagnosisDescription(): void {
    const code = this.call.diagnosis?.diagnosis;
    this.diagnosis = this.diagnosisList?.find((cie10: Cie10) => cie10.code == code)?.searchValue;
  }
}
