import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CallService } from 'src/app/services/call.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PatientService } from 'src/app/services/patient.service';
import { Call } from 'src/app/models/call.model';
import { CallStatus } from 'src/app/enums/call-status.enum';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { ToastService } from 'src/app/services/toast.service';
import { Patient } from 'src/app/models/patient.model';
import * as _ from 'lodash';
import { pairwise } from 'rxjs/operators';
import { Task } from '../../models/task.model';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css']
})
export class RateComponent implements OnInit {
  @Input('call')
  call: Call;

  readonly CallStatus = CallStatus;

  starCount = 5;
  formGroup: FormGroup;
  monitoringFormGroup: FormGroup;

  disableOtherDoctorMonitoring = false;
  disableMonitoringForm = false;
  disableMonitoringButton = false;
  hasActiveTask = false;
  supportsMonitoring = false;
  patient: Patient;
  canPrescribeMedication: boolean;
  timeRegExp: RegExp;
  patientActiveTasks: Task[];
  displayRequiresPrescription = false;

  constructor(
    private router: Router,
    private callService: CallService,
    private patientService: PatientService,
    private notificationsService: NotificationsService,
    private doctorService: DoctorService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.setTimeRegExp();
    this.buildForms();
    this.subscribeToFormChanges();

    if([CallStatus.ENDED_MANUAL, CallStatus.ENDED].includes(this.call.status as CallStatus) && this.call.provider?.requiresPrescriptionForAnotherCountry) {
      this.displayRequiresPrescription = true;
    }

    [this.canPrescribeMedication, this.patientActiveTasks] = await Promise.all([
      this.doctorService.getCanPrescribeMedication(this.call.doctor.id, this.call.id),
      this.patientService.getActivePatientTasks(this.call.patient.id)
    ]);

    this.patient = this.call.patient;

    this.supportsMonitoring = this.call.provider.supportsMonitoring || false;
    this.hasActiveTask = this.patient.onMonitoring && this.patientActiveTasks.length > 0;
    this.disableOtherDoctorMonitoring = this.patient.onMonitoring || this.patient.needsMonitoring || this.patient.needsValidation // disable if patient is already on monitoring or needs monitoring.
    this.disableMonitoringForm =
      !this.call.doctor.canMonitoring || // disable if doctor is not allowed to start monitoring
      this.patient.needsMonitoring || // disable if patient needs monitoring
      this.patient.needsValidation || // disable if patient needs validation
      this.hasActiveTask || // disable if the patient is already on monitoring with the current doctor and the current active task is not complete
      (
        this.patient.onMonitoring &&
        this.patient.doctorAssigned &&
        this.patient.doctorAssigned != this.call.doctor.id
      ) // disable if the patient is already monitoring but the doctor is no the doctor assigned
  }

  setTimeRegExp(): void {
    // Force MM:ss format. Also allows three digits for minutes, ex. 120:53
    if(
      this.call.diagnosis?.connectionFailed ||
      this.call.diagnosis?.couldNotContact ||
      this.call.diagnosis?.wrongPhone ||
      this.call.diagnosis?.consultationPreviouslySolved
    ) {
      this.timeRegExp = /^(\d{1,3}):(\d{2})$/;
    } else {
      this.timeRegExp = /^(?!00:00)(\d{1,3}):(\d{2})$/; // '00:00' not allowed
    }
  }

  buildForms(): void {
    let duration = '';
    if(this.call.doctorDuration) {
      const minutes = Math.floor(this.call.doctorDuration / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });

      const seconds = (this.call.doctorDuration - Number(minutes) * 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      });

      duration = `${minutes}:${seconds}`;
    }

    this.formGroup = this.formBuilder.group({
      doctorStars: [this.call.doctorStars || 0, [Validators.required, Validators.min(1)]],
      doctorText: { value: this.call.doctorText || '', disabled: this.call.doctorText || this.call.status === CallStatus.ENDED_MANUAL },
      doctorDuration: [ {value: duration, disabled: this.call.doctorDuration}, [Validators.required, this.timeValidator()]],
      requiresPrescription: { value: this.call.requiresPrescription, disabled: this.call.requiresPrescription },
    });

    this.monitoringFormGroup = this.formBuilder.group({
      otherDoctor: null,
    });
  }

  timeValidator(): ValidatorFn {
    return (control: AbstractControl<string>): { [key: string]: boolean } | null => {
      if (this.timeRegExp.test(control.value) && Number(control.value.split(":")[1]) < 60) {
        return null;
      }
      return { invalidTimeFormat: true };
    }
  }

  subscribeToFormChanges(): void {
    // Insert ':' after minutes
    this.doctorDuration.valueChanges.pipe(pairwise()).subscribe(([previousValue, value]) => {
      const splittedPreviousValue = previousValue.split('');
      const splittedValue = value.split('');

      if(
        splittedValue.length === 1 ||
        splittedValue[splittedValue.length - 1] === ':' ||
        splittedPreviousValue[splittedPreviousValue.length - 1] === ':'
      ) {
        return;
      }

      const number = parseInt(splittedValue[1]);
      if(splittedValue.length === 2 && _.isInteger(number)) {
        this.doctorDuration.patchValue(value + ':');
      }
    })
  }

  async submit(): Promise<void> {
    const duration = this.transformDuration();
    let updateBody = {};

    if(this.call.status === CallStatus.ENDED_MANUAL) {
      updateBody = {
        requiresPrescription: this.requiresPrescription.value,
      }
    } else {
      updateBody = {
        doctorStars: this.doctorStars.value,
        doctorText: this.doctorText.value,
        patientDuration: duration,
        doctorDuration: duration,
        status: CallStatus.ENDED_MANUAL,
        ...(this.call.status === CallStatus.ENDED && { requiresPrescription: this.requiresPrescription.value })
      }
    }

    try {
      await Promise.all([
        this.notifyToSendFilesByWhatsApp(),
        this.callService.update(this.call.id, updateBody)
      ]);

      this.router.navigate([AppPaths.MESSAGE], {state: { message: "successful_end_call" }});
    } catch (err) {
      const data = {
        message: "rate_error",
        error: err.error || err,
        title: "default_warning_message",
        actions: [{
          function: () => undefined,
          message: "default_accept"
        }]
      }
      this.notificationsService.openErrorDialog(data, false);
    }
  }

  transformDuration(): number {
    const splittedDuration = this.doctorDuration.value?.split(":");
    const minutes = Number(splittedDuration[0]);
    const seconds = Number(splittedDuration[1]);
    return minutes * 60 + seconds;
  }

  notifyToSendFilesByWhatsApp(): Promise<any> {
    const notify = {
      sendPrescriptions: this.call.sendPrescriptionByWhatsApp,
      sendOrders: this.call.sendOrderByWhatsApp,
    }
    return this.callService.notifyToSendFilesByWhatsApp(this.call.id, notify);
  }

  successMonitoring(): void {
    this.disableMonitoringButton = true;
    this.otherDoctor.disable();
    this.toastService.openSuccessToast("patient_monitoring_created");
  }

  updatePatientMonitoring(){
    this.patientService.setPatientNeedsMonitoring(this.call.patient.id, this.call.diagnosis?.diagnosis).then(() => {
      this.successMonitoring();
    }).catch(err =>{
      const error = err.error || err;
      this.notificationsService.openErrorDialog({
        message: "monitoring_error",
        error: error
      }, false)
    })
  }

  onRateClick($event: PointerEvent, rating: number): void {
    $event.preventDefault();
    this.doctorStars.setValue(rating);
  }

  get isLastIteration(): boolean {
    return this.patient.onMonitoring && !this.patientActiveTasks.length
      && (this.call.diagnosis?.connectionFailed || this.call.diagnosis?.couldNotContact || this.call.diagnosis?.wrongPhone);
  }

  // formGroup
  get doctorStars(): FormControl<number> { return this.formGroup.get('doctorStars') as FormControl }
  get doctorText(): FormControl<string> { return this.formGroup.get('doctorText') as FormControl }
  get doctorDuration(): FormControl<string> { return this.formGroup.get('doctorDuration') as FormControl }
  get requiresPrescription(): FormControl<boolean> { return this.formGroup.get('requiresPrescription') as FormControl }
  
  // monitoringFormGroup
  get otherDoctor(): FormControl<boolean> { return this.monitoringFormGroup.get('otherDoctor') as FormControl }
}
