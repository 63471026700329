import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LocaleService } from '../services/locale.service'
import { SharedDataService } from './shared-data.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient,
    private localeService: LocaleService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute) { }

  private getHeaders() {
    this.headers = new HttpHeaders({
      'Accept-Language': this.localeService.getHeaderLocale()
    });
    const token = this.sharedDataService.getToken()
    if(token) {
      this.headers = this.headers.append('Authorization', 'Bearer ' + token)
    }
  }

  private getUrl(urlEndpoint: string): string {
    if(!environment.production && !_.isEmpty(this.sharedDataService.apiUrl)) return this.sharedDataService.apiUrl + urlEndpoint;
    return environment.API_URL + urlEndpoint
  }

  parseUrlQueryParams(url: string, options: Record<string, unknown>): string {
    const urlQueryParams = options || {};
    Object.entries(urlQueryParams).forEach(([key, value]) => {
      if(typeof value === 'string') value = encodeURIComponent(value);
      url += (url.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
    });

    return url;
  }

  async get(urlEndpoint: string, criteria?: any): Promise<any> {
    this.getHeaders()
    let url = this.getUrl(urlEndpoint)

    if(criteria) {
      url = this.parseUrlQueryParams(url, criteria);
    }

    return await this.http.get(url, {headers: this.headers}).toPromise();
  }

  getObservable(urlEndpoint: string, criteria?: any, httpOptions = {}): Observable<any>{
    this.getHeaders();
    let url = this.getUrl(urlEndpoint);

    if(!_.isEmpty(criteria)) {
      url = this.parseUrlQueryParams(url, criteria);
    }

    return this.http.get(url, {headers: this.headers, ...httpOptions});
  }

  async put(urlEndpoint:string, body): Promise<any> {
    this.getHeaders()
    const url = this.getUrl(urlEndpoint)
    return await this.http.put(url, body, {headers: this.headers}).toPromise();
  }

  async post(urlEndpoint:string, body): Promise<any> {
    this.getHeaders()
    const url = this.getUrl(urlEndpoint)
    return await this.http.post(url, body, {headers: this.headers}).toPromise();
  }

  delete(urlEndpoint: string): Promise<any> {
    this.getHeaders()
    const url = this.getUrl(urlEndpoint);
    return this.http.delete(url, {headers: this.headers}).toPromise();
  }

}
