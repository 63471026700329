<div class="content" *ngIf="!loading" cdkScrollable>
    <div class="header">
        <nav mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="center" [tabPanel]="tabPanel">
            <ng-container *ngFor="let tabOption of tabBarOptions">
                <a
                    mat-tab-link
                    (click)="optionSelected = tabOption.option"
                    [active]="optionSelected === tabOption.option">
                    {{ tabOption.text | locale }}
                </a>
            </ng-container>
            <div class="bottom-bar"></div>
        </nav>

        <app-call-information [call]="call" [previousRecords]="previousRecords"/>
    </div>

    <!--
        mat-tab-nav-bar needs this to work but we don't use it.
        we're using mat-tab-nav-bar instead of mat-tab-group for styling purposes.
    -->
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

    <div [hidden]="optionSelected !== VisualizationOptions.CALL_FORM" class="confetti">
        <app-default-template *ngIf="!diagnosisComplete" label="consultation_details" [template]="diagnosis">
            <ng-template #diagnosis>
                <app-diagnosis 
                    [call]="call" 
                    [previousRecords]="previousRecords"
                    (diagnosisDuplicatedEvent)="getCall(null, $event)">
                </app-diagnosis>
            </ng-template>
        </app-default-template>

        <mat-tab-group *ngIf="diagnosisComplete" class="tab-group-info" fitInkBarToContent [(selectedIndex)]="tabGroupInfoIndex">
            <mat-tab *ngIf="call.provider.supportsPrescription && canPrescribeMedication">
                <ng-template mat-tab-label>
                    <mat-icon svgIcon="pill"></mat-icon>
                    <span *ngIf="!mobileQuery.matches">{{ "medicines_prescription" | locale }}</span>
                </ng-template>

                <app-default-template label="medicines_prescription_details" [template]="prescription">
                    <ng-template #prescription>
                        <app-prescription [call]="call" (nextStepEvent)="nextStep()"></app-prescription>
                    </ng-template>
                </app-default-template>
            </mat-tab>

            <mat-tab *ngIf="call.provider.supportsMedicalOrder && canMakeMedicalOrderInstruction">
                <ng-template mat-tab-label>
                    <mat-icon svgIcon="exams"></mat-icon>
                    <span *ngIf="!mobileQuery.matches">{{ "medical_orders" | locale }}</span>
                </ng-template>

                <app-default-template label="medical_order_details" [template]="medicalOrder">
                    <ng-template #medicalOrder>
                        <app-medical-order [call]="call" (nextStepEvent)="nextStep()"></app-medical-order>
                    </ng-template>
                </app-default-template>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon svgIcon="phone"></mat-icon>
                    <span *ngIf="!mobileQuery.matches">{{ "call_details" | locale }}</span>
                </ng-template>

                <app-default-template label="call_details" [template]="rate">
                    <ng-template #rate>
                        <app-rate [call]="call"></app-rate>
                    </ng-template>
                </app-default-template>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div [hidden]="optionSelected !== VisualizationOptions.MEDICAL_RECORDS" class="confetti">
        <app-default-template label="medical_record" [template]="hc">
            <ng-template #hc>
                <app-medical-records [call]="call" [patientCalls]="patientCalls"></app-medical-records>
            </ng-template>
        </app-default-template>
    </div>

    <div [hidden]="optionSelected !== VisualizationOptions.MEDICAL_EXAMS" class="confetti">
        <app-default-template label="tab_header_medical_exams" [template]="exams">
            <ng-template #exams>
                <app-medical-exams [call]="call" [filesByDates]="filesByDates"></app-medical-exams>
            </ng-template>
        </app-default-template>
    </div>
</div>
