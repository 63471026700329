import { AbstractControl, ValidatorFn } from "@angular/forms";

export function autocompleteObjectValidator(required = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string' && (required || control.value !== '')) {
      return { invalidAutocomplete: { value: control.value } };
    }
    return null;
  };
}
