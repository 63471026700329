<div mat-dialog-header>
    <mat-icon svgIcon="close" mat-dialog-close></mat-icon>
</div>

<mat-dialog-content>
    <app-enrollment-form
        [formGroup]="formGroup"
        [enrollment]="data.enrollment"
        [countries]="data.countries"
        [enrollmentProvinces]="data.enrollmentProvinces"
        [enrollmentTypes]="data.enrollmentTypes"
        (onSubmitEvent)="submit()">
    </app-enrollment-form>
</mat-dialog-content>
