import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from './services/locale.service';

@Pipe({
  name: 'locale'
})
export class LocalePipe implements PipeTransform {

  constructor(private localization: LocaleService) {}

  transform(key: string): string {
    return this.localization.getString(key) || key;
  }

}
