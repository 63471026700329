import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class DatepickerComponent {
  @Output()
  onDateChangeEvent = new EventEmitter();

  @Input()
  label!: string;

  @Input()
  form!: FormGroup;

  @Input()
  controlName!: string;

  @Input()
  placeholder!: string;

  get control(): FormControl { return this.form?.get(this.controlName) as FormControl }
}
