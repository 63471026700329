import { Observable } from "rxjs"
import { filter, startWith, debounceTime, distinctUntilChanged, map } from "rxjs/operators";

export function valueChangesPipe(stream: Observable<any>): Observable<any> {
  return stream.pipe(
    startWith(''),
    filter((value: string) => typeof value === 'string'),
    debounceTime(200),
    distinctUntilChanged()
  );
}
