import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent {
  @Input()
  itemText = '';

  @Input()
  itemSubtext = '';
  
  @Input()
  color = 'black';

  @Input()
  iconName?: string;

  @Input()
  srcIcon?: string;

  @Input()
  south = false;

  @Input()
  suffix = true;

  @Input()
  class: string;

  @Input()
  formGroup: FormGroup;

  @Input()
  controlName: string;

  @Input()
  collapsable = false;

  @Input()
  itemDetailTemplate!: TemplateRef<any>;

  @Input()
  data: any;

  @Input()
  index: number;

  @Input()
  openCollapsable = false;

  @Input()
  displayTrashButton = false;

  @Output()
  toggleSwitch = new EventEmitter<boolean>();

  @Output()
  removeEvent = new EventEmitter<number>();

  @ViewChild(MatCheckbox) matCheckbox: MatCheckbox;

  onClickComponent(event: PointerEvent): void {
    event.preventDefault();

    if(this.collapsable)
      this.openCollapsable = !this.openCollapsable;

    if(this.matCheckbox && !this.matCheckbox.disabled) {
      const checkboxControl = this.formGroup.get(this.controlName) as FormControl;
      this.formGroup.get(this.controlName).setValue(!checkboxControl.value);
    }
  }

  removeAction(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.removeEvent.emit(this.index);
  }
}
