import { CallResolutions } from "../enums/call-resolutions.enum";

export const CALL_RESOLUTIONS = [
    { value: CallResolutions.SOLVED, name: "diagnosis_option_solved" },
    { value: CallResolutions.URGENT, name: "diagnosis_option_urgent" },
    { value: CallResolutions.REFERRED, name: "diagnosis_option_referred" },
    { value: CallResolutions.CONNECTION_FAILED, name: "diagnosis_option_connectionFailed" },
    { value: CallResolutions.COULD_NOT_CONTACT, name: "diagnosis_option_couldNotContact" },
    { value: CallResolutions.WRONG_PHONE, name: "diagnosis_option_wrongPhone" },
    { value: CallResolutions.CONSULTATION_PREVIOUSLY_SOLVED, name: "diagnosis_option_consultationPreviouslySolved" },
    { value: CallResolutions.PATIENT_DISCHARGED, name: "diagnosis_option_patientDischarged" },
    { value: CallResolutions.DISCONTINUE_MONITORING, name: "diagnosis_option_discontinueMonitoring" },
];
