import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Prescription } from '../models/prescription.model';
import { MedicalOrder } from '../models/medical-order.model';
import { Call } from '../models/call.model';
import { MedicinePrescription } from '../models/medicine-prescription.model';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  constructor(private requestService: RequestService) { }

  getOne(callId: string): Promise<Call> {
    return this.requestService.get('/call/' + callId)
  }

  update(callId: string, body: any): Promise<void> {
    return this.requestService.put('/call/' + callId, body)
  }

  checkPendingStatus(callId: string) {
    return this.requestService.get('/call/pending/' + callId + '/status')
  }

  createPrescription(body: {
    call: string,
    medicines: MedicinePrescription[],
    prolongedTreatment: boolean,
    sendDuplicates: boolean,
    isPrescription: boolean,
  }): Promise<Prescription[]> {
    return this.requestService.post('/prescription', body)
  }

  createMedicalOrder(body: { call: string, medicalOrders: MedicalOrder[] }): Promise<MedicalOrder[]> {
    return this.requestService.post('/medicalorder?checkDuplicatedDiagnosis=false', body);
  }

  notifyToSendFilesByWhatsApp(callId: string, data: any): Promise<any> {
    return this.requestService.post('/call/' + callId + '/sendFilesTemplate', data)
  }

  patientHasRepeatedAbsenteeism(patientId: string): Promise<boolean> {
    return this.requestService.get(`/call/patient/${patientId}/repeatedAbsenteeism`);
  }

  getPatientCalls(callId: string, skip: number, limit: number, count = false): Promise<{ data: Call[], total: number } | Call[]> {
    return this.requestService.get(`/call/${callId}/patientCalls`, { skip, limit, ...(count && { count }) });
  }
}
