import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Appointment } from './../models/appointment.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  baseApiURL = '/appointment';

  constructor(private requestService: RequestService) { }

  cancelWithToken(): Promise<any> {
    return this.requestService.post(`${this.baseApiURL}/cancelWithToken`, {});
  }

  getOne(): Promise<Appointment> {
    return this.requestService.get(`${this.baseApiURL}/getWithCancelToken`);
  }
}
