<div class="item-container" [class]="class" [ngClass]="{'overlap': collapsable}" (click)="onClickComponent($event)">
    <div *ngIf="srcIcon || iconName" class="item-icon">
        <div class="icon-background" [ngClass]="color" [ngStyle]="{'background-color':color}">
            <img *ngIf="srcIcon" [src]="srcIcon" [alt]="itemText">
            <mat-icon
                *ngIf="!srcIcon && iconName"
                [svgIcon]="iconName"
                [ngClass]="[iconName, color]"
                [ngStyle]="{'color': 'white'}">
            </mat-icon>
        </div>
    </div>
    <div class="item-text-container" [ngClass]="{'expanded': formGroup}" [ngClass]="{'full-width': !formGroup && !suffix}">
        <div class="item-text-content" [ngClass]="{'no-button': !displayTrashButton}">
            <h4 class="item-text">{{ itemText }}</h4>
            <h4 class="item-subtext" *ngIf="itemSubtext">{{ itemSubtext }}</h4>
        </div>
        <button
            *ngIf="displayTrashButton"
            mat-icon-button
            class="trash-button"
            (click)="removeAction($event)">
            <mat-icon svgIcon="flat-trash-can" [class]="class"></mat-icon>
        </button>
    </div>

    <form [formGroup]="formGroup" *ngIf="formGroup; else defaultSuffix">
        <mat-checkbox #customCheckbox color="primary" [formControlName]="controlName"></mat-checkbox>
    </form>

    <ng-template #defaultSuffix>
        <div class="item-arrow" *ngIf="suffix">
            <mat-icon
                matIconSuffix
                svgIcon="arrow-right"
                [ngClass]="{'south': south, 'rotate': south && openCollapsable}"
                [ngStyle]="{'color': !south ? color : 'black'}">
            </mat-icon>
        </div>
    </ng-template>
</div>

<ng-container
    [ngTemplateOutletContext]="{ openCollapsable, data, index }"
    [ngTemplateOutlet]="itemDetailTemplate">
</ng-container>
