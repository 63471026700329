import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.css']
})
export class DefaultTemplateComponent {
  @Input()
  template: TemplateRef<any>;

  @Input()
  label: string;

}
