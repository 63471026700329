import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CallService } from 'src/app/services/call.service';
import { NotificationsService } from 'src/app/services/notifications.service'
import { Call } from 'src/app/models/call.model';
import { Prescription } from 'src/app/models/prescription.model';
import { Medicine } from 'src/app/models/medicine.model';
import { MedicinePrescription } from 'src/app/models/medicine-prescription.model';
import { autocompleteObjectValidator } from 'src/app/utils/autocomplete-validators.function';
import { MedicineFormComponent } from 'src/app/call-form/medicine-form/medicine-form.component';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { CallStatus } from 'src/app/enums/call-status.enum';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css']
})
export class PrescriptionComponent implements OnInit {
  @Input()
  call: Call;

  @Output()
  nextStepEvent = new EventEmitter<void>();

  @ViewChild(MedicineFormComponent) medicineFormComponent: MedicineFormComponent;

  isMedicineFormValid = false;
  hasSomeFilledFields = false;
  formGroup: FormGroup;
  resetSubject = new Subject<void>;

  constructor(
    private callService: CallService,
    private notificationsService: NotificationsService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.checkProlongedTreatment();
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      isPrescription: {value: this.call.isPrescription, disabled: this.call.status === CallStatus.ENDED_MANUAL},
      prolongedTreatment: false,
      sendDuplicates: false,
      prescriptions: this.formBuilder.array(this.fillMedicineForms()),
    });
  }

  fillMedicineForms(): FormGroup[] {
    const formGroups: FormGroup[] = [];

    this.call?.prescriptions?.forEach((prescription: Prescription) => {
      const prescriptedMedicine = this.getPrescriptedMedicine(prescription);
      formGroups.push(this.setMedicineFormArray(prescriptedMedicine))
    });
    
    return formGroups;
  }

  getPrescriptedMedicine(prescription: Prescription): MedicinePrescription {
    return {
      medicine: prescription.medicines[0],
      quantity: prescription.medicines[0].quantity,
      instructions: prescription.medicines[0].instructions,
      status: prescription.status,
    }
  }

  private setMedicineFormArray(medicinePrescripted: MedicinePrescription): FormGroup {
    const disabled = !!medicinePrescripted.status;
    return this.formBuilder.group({
      medicine: [{value: medicinePrescripted.medicine, disabled}, [Validators.required, autocompleteObjectValidator()]],
      quantity: [{value: medicinePrescripted.quantity, disabled}, [Validators.required, Validators.min(1), Validators.max(100)]],
      instructions: [{value: medicinePrescripted.instructions || "", disabled}, [Validators.required]],
      status: medicinePrescripted.status,
    });
  }

  onSelectMedicine(selectedMedicine: { medicine: Medicine, index: number }): void {
    const prescriptionFormGroup = this.prescriptions.controls.find(
      (prescriptionFormGroup: FormGroup, index) =>
        selectedMedicine.index != index &&
        prescriptionFormGroup.get("medicine")?.value?.id === selectedMedicine.medicine.id
    );

    if(prescriptionFormGroup) {
      this.notificationsService.duplicatedMedicineMessage();
      this.medicineFormComponent.resetMedicineControl();
      this.prescriptions.controls[selectedMedicine.index]?.get("medicine").reset();
      let currentQuantity: number = prescriptionFormGroup.get("quantity").value;
      prescriptionFormGroup.get("quantity").setValue(++currentQuantity);
      this.checkProlongedTreatment();
    }
  }

  addMedicinePrescription(medicine: MedicinePrescription): void {
    this.prescriptions.push(this.setMedicineFormArray(medicine));
  }

  checkIsValidMedicineForm(isValid: boolean): void {
    this.isMedicineFormValid = isValid;
    const { medicine, instructions } = this.medicineFormComponent;
    this.hasSomeFilledFields = medicine?.value || instructions.value?.trim();
  }

  skipStep(): void {
    this.nextStepEvent.emit();
  }

  next(): void {
    const prescriptionData = {
      call: this.call.id,
      medicines: this.getPrescriptedMedicines(),
      prolongedTreatment: this.prolongedTreatment.value,
      sendDuplicates: this.sendDuplicates.value,
      isPrescription: this.isPrescription.value,
    }

    this.callService.createPrescription(prescriptionData).then((createdPrescriptions) => {
      // Remove new prescriptions from array
      _.forEachRight(this.prescriptions.controls, (formGroup: FormGroup, index) => {
        if(!formGroup.controls.status?.value) this.prescriptions.removeAt(index);
      })

      createdPrescriptions?.forEach((prescription) => {
        this.call.prescriptions.push(prescription);
        // Push created prescriptions into array
        const prescriptedMedicine = this.getPrescriptedMedicine(prescription);
        this.prescriptions.push(this.setMedicineFormArray(prescriptedMedicine));
      });

      // Reset form
      this.resetSubject.next();

      // Go to next step
      this.nextStepEvent.emit();
    })
    .catch((err) => {
      this.notificationsService.openErrorDialog({
        title: "dialog_title_warning",
        message: "prescription_error",
        actions: [{
          function: () => undefined,
          message: "close"
        }],
        error: err.error || err
      }, false)
    })
  }

  getPrescriptedMedicines(): MedicinePrescription[] {
    let medicines: MedicinePrescription[] = [];

    this.prescriptions.value?.forEach((medicinePrescription: MedicinePrescription) => {
      if(!medicinePrescription.status) {
        medicines.push({
          id: medicinePrescription.medicine.id,
          instructions: medicinePrescription.instructions,
          quantity: medicinePrescription.quantity
        });
      }
    });
    
    const { medicine, quantity, instructions } = this.medicineFormComponent.formGroup.value;

    if(medicine?.id && quantity && instructions)
      medicines = [...medicines, { id: medicine.id, quantity, instructions }];

    return medicines;
  }

  checkProlongedTreatment(): void {
    const prolongedTreatment = this.prescriptions?.controls.some((formGroup: FormGroup) =>
      formGroup.get("quantity").value >= 2
    );

    const { medicine, quantity } = this.medicineFormComponent || {};

    if((medicine?.valid && medicine?.value && quantity?.value >= 2) || prolongedTreatment) {
      this.prolongedTreatment.disable();
      this.prolongedTreatment.setValue(true);
    } else {
      this.prolongedTreatment.enable();
      this.prolongedTreatment.setValue(false);
    }
  }

  get prescriptions(): FormArray { return this.formGroup.get('prescriptions') as FormArray }
  get isPrescription(): FormControl<boolean> { return this.formGroup.get('isPrescription') as FormControl }
  get prolongedTreatment(): FormControl<boolean> { return this.formGroup.get('prolongedTreatment') as FormControl }
  get sendDuplicates(): FormControl<boolean> { return this.formGroup.get('sendDuplicates') as FormControl }
}
