import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { Title } from '@angular/platform-browser';
import { AppointmentService } from 'src/app/services/appointment.service';
import { Appointment } from 'src/app/models/appointment.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
import { AppPaths } from 'src/app/enums/app-paths.enum';

@Component({
  selector: 'app-appointment-cancel',
  templateUrl: './appointment-cancel.component.html',
  styleUrls: ['./appointment-cancel.component.css']
})
export class AppointmentCancelComponent implements OnInit {
  appointment!: Appointment;
  disableCancelButton = false;

  constructor(
    private localeService: LocaleService,
    private title: Title,
    private appointmentService: AppointmentService,
    private dialog: MatDialog,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.title.setTitle(this.localeService.getString('appointment_cancel_title'));
  }

  async ngOnInit(): Promise<void> {
    await this.getAppointment();

    if(this.appointment?.cancelled) {
      this.disableCancelButton = true;
      this.openDialog("appointment_cancel_already_cancelled_dialog");
      return;
    }

    if(!this.canCancelAppointment()) return;
  }

  async getAppointment(): Promise<void> {
    try {
      this.appointment = await this.appointmentService.getOne();
    } catch (error) {
      this.router.navigate([AppPaths.MESSAGE], {state: { message: "appointment_cannot_cancel" }});
    }
  }

  openDialog(message: string, fn = () => {}): void {
    const data = {
      message: message,
      title: "default_warning_message",
      actions: [{
        function: fn,
        message: "default_accept"
      }]
    }

    this.dialog.open(DialogComponent, {
      width: "400px",
      panelClass: 'dark-dialog',
      disableClose: true,
      data,
    });
  }

  canCancelAppointment(): boolean {
    const duration = moment.duration(moment(this.appointment.date).diff(moment()));
    const minutes = duration.asMinutes();

    if(minutes < 10) { // 10 minutes or less left to start appointment
      this.disableCancelButton = true;
      this.openDialog("appointment_cancel_about_to_start");
      return false;
    }

    return true;
  }

  async submit(): Promise<void> {
    if(!this.canCancelAppointment()) return;

    try {
      await this.appointmentService.cancelWithToken();
      this.openDialog("appointment_cancel_cancelled_dialog", () => { this.disableCancelButton = true });
    } catch (error) {
      this.toastService.openErrorToast(error?.error?.message);
    }
  }
}
