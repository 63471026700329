<div class="container">
    <div class="section-container">
        <div class="section left">
            <div class="content">
                <h1 class="title">{{ 'appointment_cancel_title' | locale }}</h1>
            </div>
        </div>

        <div class="section center" *ngIf="appointment">
            <div class="content">
                <div class="white-container">
                    <span class="doctor">{{ (appointment.doctor?.title ? appointment.doctor?.title + ' ' : '') + appointment.doctor?.name }}</span>
                    <span class="patient">{{ appointment.patient?.name }}</span>
                    <span class="specialty">{{ appointment.specialty?.name }}</span>
                    <span class="provider">{{ appointment.provider?.title || appointment.provider?.name }}</span>
                    <span class="date">{{ appointment.date | date: 'dd/MM/yyyy HH:mm' }}hs.</span>
                </div>

                <button
                    type="submit"
                    mat-flat-button
                    color="warn"
                    [disabled]="disableCancelButton"
                    (click)="submit()">
                    {{ 'appointment_cancel_title' | locale }}
                    <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
                </button>
            </div>
        </div>

        <div class="section right"></div>
    </div>
</div>

