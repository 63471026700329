import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
})
export class AutocompleteComponent {
  @Output()
  optionSelectedEvent = new EventEmitter<any>();

  @Output()
  removedOptionEvent = new EventEmitter<void>();

  @Input()
  label?: string;

  @Input()
  form!: FormGroup;

  @Input()
  controlName!: string;

  @Input()
  items!: any;

  @Input()
  displayFn!: (item: any) => string;

  @Input()
  placeholder?: string;

  handleClickIcon(): void {
    this.control.reset('');
    this.removedOptionEvent.emit();
  }

  get control(): FormControl { return this.form?.get(this.controlName) as FormControl }
}
