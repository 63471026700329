<mat-label [isRequired]="control">{{ label | locale }}</mat-label>
<mat-form-field color="accent">
    <mat-select
        [formControlName]="controlName"
        [compareWith]="objectComparisonFunction"
        panelClass="select-custom-panel"
        disableOptionCentering
        [placeholder]="placeholder ? (placeholder | locale) : ''"
        (selectionChange)="selectedOption.emit($event)">
        <mat-option *ngFor="let item of items" [value]="item">
            {{ displayValue(item) }}
        </mat-option>
    </mat-select>
    <mat-icon
        matIconSuffix
        class="select-suffix"
        svgIcon="short-arrow-down">
    </mat-icon>
    <mat-error>{{ control | errorMessage }}</mat-error>
</mat-form-field>
