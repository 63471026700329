<div class="container">
    <h1 class="title">{{ "tab_info_title" | locale }}</h1>

    <div class="grid">
        <p>{{ "createdAt" | locale }}: {{ call?.createdAt | date: "dd/MM/yyyy, HH:mm'hs'" }}</p>
        <p>{{ "patient" | locale }}: {{ call?.patient?.name || "-" }}</p>
        <p>{{ "birth_date" | locale }}: {{ patientBirthDate ? (patientBirthDate | date: "dd/MM/yyyy") + ' (' + call?.patient?.age + ' ' + ("years_old" | locale) + ')' : '-' }}
        <p>{{ "call_provider" | locale }}: {{ call?.provider?.title || call?.provider?.name || "-" }}</p>
        <p>{{ "motive" | locale }}: {{ call?.motive || call?.diagnosis?.motive || "-" }}</p>
        <p>{{ "medical_history" | locale }}: {{ medicalHistory || "-" }}</p>
        <ng-container *ngIf="call?.diagnosis">
            <p>{{ "call_history_diagnosis" | locale }}: {{ diagnosis }}</p>
            <p>{{ "diagnosis_text_label" | locale }}: {{ call.diagnosis.text || "-" }}</p>
        </ng-container>
    </div>
</div>
