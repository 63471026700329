import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Country } from 'src/app/models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  baseApiURL = '/country';

  constructor(private requestService: RequestService) { }

  get(): Promise<Country[]> {
    return this.requestService.get(`${this.baseApiURL}?sort=name ASC`);
  }
}
