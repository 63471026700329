import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.css']
})
export class FileInputComponent implements OnInit {
  @Output()
  uploadFileEvent = new EventEmitter<Event>();

  @Output()
  removeFileEvent = new EventEmitter<string>();

  @Input()
  files!: { fileName: string, url: string }[];

  @Input()
  supportedExtensions: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  openFile(url: string): void {
    window.open(url);
  }

}
