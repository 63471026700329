<div mat-dialog-header>
    <mat-icon svgIcon="close" mat-dialog-close></mat-icon>
</div>

<mat-dialog-content *ngIf="medicalExam">
    <div class="image-container" #imageContainer>
        <img
            *ngIf="medicalExam?.fileFormat !== mimeTypes.PDF; else pdfReader"
            [src]="fileUrl"
            alt="Medical exam"
            width="100%"
            [ngClass]="{'zoom': zoom }"
        />

        <ng-template #pdfReader>
            <pdf-viewer
                [src]="fileUrl"
                [render-text]="true"
                [original-size]="false"
                [zoom]="!zoom ? 1 : 1.5">
            </pdf-viewer>
        </ng-template>
    </div>

    <button mat-icon-button class="zoom-btn" (click)="zoom = !zoom">
        <mat-icon svgIcon="zoom"></mat-icon>
    </button>
</mat-dialog-content>
