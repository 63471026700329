import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { LocaleService } from "src/app/services/locale.service";

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {

  constructor(
    private localeService: LocaleService,
  ) { }

  transform(value: Date | moment.Moment | string | number, dateFormat: string): string {
    const locale = this.localeService.locale;
    moment.locale(locale); 
    return moment(value).format(dateFormat);
  }
}
