import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Call } from 'src/app/models/call.model';
import { MedicalExamDateInfo, MedicalExamResult } from 'src/app/models/medical-exam-result.model';
import { PatientService } from 'src/app/services/patient.service';
import { ToastService } from 'src/app/services/toast.service';
import { MedicalExamDialogComponent } from './components/medical-exam-dialog/medical-exam-dialog.component';

@Component({
  selector: 'app-medical-exams',
  templateUrl: './medical-exams.component.html',
  styleUrls: ['./medical-exams.component.css']
})
export class MedicalExamsComponent {
  @Input()
  call: Call;

  @Input()
  filesByDates: MedicalExamDateInfo[];

  constructor(
    private patientService: PatientService,
    private toastService: ToastService,
    private matDialog: MatDialog,
  ) {}

  async getFileUrl(medicalExam: MedicalExamResult): Promise<string> {
    try {
      const response = await this.patientService.getFileUrl(medicalExam.patient.id, medicalExam.id);
      return response.url;
    } catch (error) {
      console.error(error);
      this.toastService.openErrorToast('error_default');
    }
  }

  async downloadFile(medicalExam: MedicalExamResult): Promise<void> {
    const url = await this.getFileUrl(medicalExam);
    const name = medicalExam.name;

    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = name;
    a.click();
  }

  async openDetail(medicalExam: MedicalExamResult): Promise<void> {
    this.matDialog.open(MedicalExamDialogComponent, {
      panelClass: ['file-dialog-container'],
      width: '80%',
      maxWidth: '90vw',
      maxHeight: '80vh',
      data: {
        medicalExam: medicalExam,
        url: await this.getFileUrl(medicalExam),
      },
    });
  }
}
