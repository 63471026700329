import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MimeTypes } from 'src/app/enums/mime-types.enum';
import { MedicalExamResult } from 'src/app/models/medical-exam-result.model';

@Component({
  selector: 'app-medical-exam-dialog',
  templateUrl: './medical-exam-dialog.component.html',
  styleUrls: ['./medical-exam-dialog.component.css']
})
export class MedicalExamDialogComponent {
  zoom = false;
  medicalExam: MedicalExamResult;
  readonly mimeTypes = MimeTypes;
  fileUrl = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { medicalExam: MedicalExamResult, url: string },
  ) {
    this.medicalExam = data.medicalExam;
    this.fileUrl = data.url;
  }
}
