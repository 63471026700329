<form [formGroup]="formGroup">
    <h2 class="title-accent" style="margin: 2rem 0 1rem;">{{ 'documentation_enrollment_title' | locale }}</h2>

    <div class="white-container">
        <app-enrollment-form
            [formGroup]="formGroup"
            [countries]="countries"
            [enrollmentProvinces]="enrollmentProvinces"
            [enrollmentTypes]="enrollmentTypes"
            [resetFormSubject]="resetFormSubject"
            (onSubmitEvent)="submit()">
        </app-enrollment-form>

        <div class="container" *ngIf="dataSource?.data?.length > 0">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>{{ 'documentation_enrollment_table_type' | locale }}</th>
                    <td mat-cell *matCellDef="let item"> {{ item.enrollmentType.name }} </td>
                </ng-container>

                <ng-container matColumnDef="enrollment">
                    <th mat-header-cell *matHeaderCellDef>{{ 'documentation_enrollment_table_code' | locale }}</th>
                    <td mat-cell *matCellDef="let item" class="enrollment-number"> {{ item.number }} </td>
                </ng-container>

                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>{{ 'documentation_enrollment_table_country' | locale }}</th>
                    <td mat-cell *matCellDef="let item"> {{ item.country.name }} </td>
                </ng-container>

                <ng-container matColumnDef="expirationDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'documentation_enrollment_table_date' | locale }}</th>
                    <td mat-cell *matCellDef="let item"> {{ (item.expirationDate | date: "dd/MM/yyyy") || '-' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item" class="action-data">
                        <button
                            mat-icon-button
                            class="action-button"
                            (click)="openEditEnrollmentDialog(item)">
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            class="action-button"
                            (click)="deleteEnrollment(item)">
                            <mat-icon svgIcon="trash"></mat-icon>
                        </button>

                        <button
                            *ngIf="item.fileS3Key"
                            mat-icon-button
                            class="action-button"
                            (click)="openFile(item.fileS3Key)">
                            <mat-icon svgIcon="picture-icon"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

    </div>
</form>
