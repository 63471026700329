<form #form="ngForm" [formGroup]="formGroup" (ngSubmit)="submitForm()">
    <app-autocomplete
        [form]="formGroup"
        controlName="medicine"
        label="prescription_medicine_label"
        placeholder="prescription_medicine_placeholder"
        [items]="filteredMedicines"
        [displayFn]="displayMedicineFn"
        (optionSelectedEvent)="onSelectMedicine.emit({medicine: $event, index})"
        (onSelectedRemoved)="onRemoveMedicine()">
    </app-autocomplete>
  
    <mat-label [isRequired]="quantity">{{ 'prescription_medicine_quantity' | locale }}</mat-label>
    <mat-form-field>
        <input
            matInput
            type="number"
            [placeholder]="'prescription_indicate_quantity' | locale"
            formControlName="quantity"
            autocomplete="off"
        />
        <mat-error>{{ quantity | errorMessage }}</mat-error>
    </mat-form-field>
  
    <mat-label [isRequired]="quantity">{{ 'prescription_medicine_indications' | locale }}</mat-label>
    <mat-form-field color="primary" class="textarea">
        <textarea
            matInput
            rows="4"
            [placeholder]="'prescription_medicine_indications' | locale"
            formControlName="instructions">
        </textarea>
        <mat-error>{{ instructions | errorMessage }}</mat-error>
    </mat-form-field>
  
    <button
        *ngIf="displaySubmitButton"
        mat-button
        type="submit"
        [disabled]="!formGroup.valid || !medicine.value?.id"
        color="accent">
        {{ 'prescription_add_button' | locale }}
    </button>
</form>
  