import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Practice } from '../models/practice.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PracticeService {
  constructor(private requestService: RequestService) {}

  get(providerId?: string): Promise<Practice[]> {
    const criteria: any = {
      active: true,
    };

    if (providerId) criteria.provider = providerId;
    else criteria.lad = true;
  
    return this.requestService.get(`/practice`, criteria);
  }

  getByName(providerId?: string, name?: string): Observable<Practice[]> {
    const criteria: any = {
      active: true,
      name: name,
    }

    if(providerId) criteria.provider = providerId;
      else criteria.lad = true;

    return this.requestService.getObservable(`/practice`, criteria);
  }
}
