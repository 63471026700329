<ng-container *ngIf="filesByDates && filesByDates.length > 0; else noData">
    <ng-container *ngFor="let element of filesByDates">
        <p class="date">{{ element.date }}</p>
    
        <ul class="medical-records">
            <li *ngFor="let file of element.files">
                <div class="item-icon" [ngStyle]="{ 'background-color': file.typeData?.color }">
                    <mat-icon [svgIcon]="file.typeData?.icon" [class]="file.typeData?.icon"></mat-icon>
                </div>
    
                <div class="info">
                    <p class="medical-records__title">{{ file.name }}</p>
                    <p class="medical-records__subtitle">{{ file.typeData?.text | locale }}</p>
                    <p class="medical-records__size">{{ +file.size | fileSize }}</p>
                </div>
    
                <div class="actions">
                    <button
                        mat-icon-button
                        (click)="downloadFile(file)">
                        <mat-icon svgIcon="arrow-right" class="download-icon"></mat-icon>
                    </button>
    
                    <button
                        mat-icon-button
                        (click)="openDetail(file)">
                        <mat-icon svgIcon="visibility" class="detail-icon"></mat-icon>
                    </button>
                </div>
            </li>
        </ul>
    </ng-container>
</ng-container>

<ng-template #noData>
    <div class="no-data-container">
        <span>{{ 'medical_exams_empty_message' | locale }}</span>
    </div>
</ng-template>
