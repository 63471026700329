import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Medicine } from '../models/medicine.model';

@Injectable({
  providedIn: 'root',
})
export class MedicineService {
  constructor(
    private requestService: RequestService
  ) {}

  get(providerId: string, medicine: string): any {
    return this.requestService.getObservable(`/medicine?provider=${providerId}&q=${medicine}&limit=500`);
  }

  getMedicineName(medicine: Medicine): string {
    if(medicine) {
      const medicineText = 
        (medicine.active && medicine.packaging && medicine.drugs) ?
          `${medicine.active} x ${medicine.packaging} x ${medicine.drugs}`:
          `${medicine.active}` + (medicine.drugs ? ` x ${medicine.drugs}` : '');
      return medicineText;
    }
    return '';
  }
}
