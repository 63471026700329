import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { DiagnosisService } from 'src/app/services/diagnosis.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { map } from 'rxjs/operators';
import { NotificationsService } from 'src/app/services/notifications.service'
import { MedicalHistoryService } from 'src/app/services/medical-history.service'
import { valueChangesPipe } from 'src/app/utils/value-changes-pipe.function';
import { Cie10 } from 'src/app/models/cie10.model';
import { normalize } from 'src/app/utils/sanitizer.function';
import { errorStateMatcher } from 'src/app/utils/error-state-matcher.function';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { ErrorStateMatcher } from '@angular/material/core';
import { Call } from 'src/app/models/call.model';
import { CALL_RESOLUTIONS } from 'src/app/consts/call-resolutions.const';
import { LocaleService } from 'src/app/services/locale.service';
import { CallResolutions } from 'src/app/enums/call-resolutions.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogData } from 'src/app/models/dialog-data.model';

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css'],
  providers: [
    { provide: ErrorStateMatcher, useClass: errorStateMatcher },
  ]
})
export class DiagnosisComponent implements OnInit {
  @Input('call') call: Call;

  @Input() previousRecords?: string[];

  @Output()
  diagnosisDuplicatedEvent = new EventEmitter<boolean>();

  resolutionOptions = CALL_RESOLUTIONS;

  failedResolutions = [
    CallResolutions.CONNECTION_FAILED,
    CallResolutions.COULD_NOT_CONTACT,
    CallResolutions.WRONG_PHONE,
    CallResolutions.CONSULTATION_PREVIOUSLY_SOLVED
  ];

  formGroup = new FormGroup({
    diagnosis: new FormControl('', [Validators.required]),
    text: new FormControl('', [Validators.required]),
    resolution: new FormControl(null, [Validators.required]),
    problemPatient: new FormControl(false),
    notes: new FormControl(''),
    motive: new FormControl('', [Validators.required]),
    patientRecords: new FormControl('', [Validators.required]),
    treatment: new FormControl('', [Validators.required]),
    requiresPrescription: new FormControl(false),
  })

  diagnosisList: Cie10[] = [];
  filteredDiagnosisList: Observable<Cie10[]>;

  readonly treatmentMaxLength = 250;

  constructor(
    private diagnosisService: DiagnosisService,
    private notificationsService:NotificationsService,
    private sharedDataService: SharedDataService,
    private medicalHistoryService: MedicalHistoryService,
    private localeService: LocaleService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getCie10();

    this.subscribeToFormChanges();
    this.patchFormValues();
  }

  patchFormValues(): void {
    this.formGroup.patchValue({
      patientRecords: this.medicalHistoryService.getMedicalHistory(this.call.records || this.previousRecords),
      motive: this.call.motive || '',
    })
  }

  continue(): void {
    const selectedResolution = this.resolution.value;
    const notes = this.notes.value?.trim();
    const newDiagnosis = {
      call: this.call.id,
      diagnosis: this.diagnosis.value?.code,
      solved: selectedResolution.value === CallResolutions.SOLVED,
      urgent: selectedResolution.value === CallResolutions.URGENT,
      referred: selectedResolution.value === CallResolutions.REFERRED,
      connectionFailed: selectedResolution.value === CallResolutions.CONNECTION_FAILED,
      patientDischarged: selectedResolution.value === CallResolutions.PATIENT_DISCHARGED,
      couldNotContact: selectedResolution.value === CallResolutions.COULD_NOT_CONTACT,
      wrongPhone: selectedResolution.value === CallResolutions.WRONG_PHONE,
      consultationPreviouslySolved: selectedResolution.value === CallResolutions.CONSULTATION_PREVIOUSLY_SOLVED,
      discontinueMonitoring: selectedResolution.value === CallResolutions.DISCONTINUE_MONITORING,
      text: this.text.value,
      problemPatient: this.problemPatient.value,
      motive: this.motive.value,
      patientRecords: this.patientRecords.value,
      treatment: this.treatment.value,
      requiresPrescription: this.requiresPrescription.value,
      ...(notes && {notes})
    }

    this.diagnosisService.create(newDiagnosis).then((diagnosis) => {
      this.call.diagnosis = diagnosis;
      this.sharedDataService.diagnosisComplete = true;
    }).catch((err) => {
      const duplicatedDiagnosis = err.error?.name === "diagnosisAlreadyExists";
      const data = this.handleDiagnosisError(duplicatedDiagnosis, err);
      this.notificationsService.openErrorDialog(data, duplicatedDiagnosis);
    })
  }

  private handleDiagnosisError(duplicatedDiagnosis: boolean, err: HttpErrorResponse): Partial<DialogData> {
    let data: Partial<DialogData>;
    if(duplicatedDiagnosis) {
      this.diagnosisDuplicatedEvent.emit(true);
      data = {
        message: err.error.message,
        title: "default_warning_message",
        actions: [{
          function: () => undefined,
          message: "continue"
        }]
      }
    } else {
      data = {
        message: "diagnosis_error",
        error: err.error || err,
        title: "default_warning_message",
        actions: [{
          function: () => undefined,
          message: "default_accept"
        }]
      }
    }
    return data;
  }

  checkOptionalFields(event: MatSelectChange): void {
    const resolution = _.find(this.resolutionOptions, resolution => resolution === event.value);
    if(this.failedResolutions.includes(resolution.value)) {
      this.clearFieldsValidators();
      this.updateFormValueAndValidity();
    } else {
      this.setRequiredValidatorToFields();
      if(this.requiresPrescription?.value) this.addTreatmentMaxLengthValidator();
      this.updateFormValueAndValidity();
    }
  }

  addTreatmentMaxLengthValidator(): void {
    this.treatment.addValidators(Validators.maxLength(this.treatmentMaxLength));
    if(this.treatment.value?.length > 0) this.treatment.markAllAsTouched();
  }

  async getCie10(): Promise<void> {
    this.diagnosisList = await this.diagnosisService.getCie10();
    this.filteredDiagnosisList = of(this.diagnosisList);
  }

  updateFormValueAndValidity(): void {
    this.text.updateValueAndValidity();
    this.motive.updateValueAndValidity();
    this.patientRecords.updateValueAndValidity();
    this.treatment.updateValueAndValidity();
    this.diagnosis.updateValueAndValidity();
  }

  clearFieldsValidators(): void {
    this.text.clearValidators();
    this.motive.clearValidators();
    this.patientRecords.clearValidators();
    this.treatment.clearValidators();
    this.diagnosis.clearValidators();
  }

  setRequiredValidatorToFields(): void {
    this.text.setValidators([Validators.required]);
    this.motive.setValidators([Validators.required]);
    this.patientRecords.setValidators([Validators.required]);
    this.treatment.setValidators([Validators.required]);
    this.diagnosis.setValidators([Validators.required]);
  }

  private subscribeToFormChanges(): void {
    this.filteredDiagnosisList = valueChangesPipe(this.diagnosis.valueChanges)
    .pipe(map((value: string) => this.filterItemsAutocomplete(this.diagnosisList, value)));

    this.requiresPrescription.valueChanges.subscribe((requiresPrescription) => {
      if(requiresPrescription) this.addTreatmentMaxLengthValidator();
      else this.treatment.setValidators(Validators.required)
    })
  }

  private filterItemsAutocomplete(items: Cie10[], valueToSearch: string): Cie10[] {
    valueToSearch = normalize(valueToSearch.toLowerCase().trim());
    return items.filter((item: Cie10) =>
      normalize(item.searchValue?.toLowerCase()).includes(valueToSearch)
    );
  }

  displayDiagnosisFn(diagnosis: {searchValue: string}): string {
    return diagnosis?.searchValue || '';
  }

  displayResolutionFn = (resolution?: {value: string, name: string}): string => {
    return resolution ? this.localeService.getString(resolution.name) : '';
  }

  get diagnosis(): FormControl<Cie10> { return this.formGroup.get('diagnosis') as FormControl }
  get resolution(): FormControl<{value: string, name: string}> { return this.formGroup.get('resolution') as FormControl }
  get motive(): FormControl<string> { return this.formGroup.get('motive') as FormControl }
  get patientRecords(): FormControl<string> { return this.formGroup.get('patientRecords') as FormControl }
  get text(): FormControl { return this.formGroup.get('text') as FormControl }
  get treatment(): FormControl<string> { return this.formGroup.get('treatment') as FormControl }
  get notes(): FormControl<string> { return this.formGroup.get('notes') as FormControl }
  get problemPatient(): FormControl<boolean> { return this.formGroup.get('problemPatient') as FormControl }
  get requiresPrescription(): FormControl<boolean> { return this.formGroup.get('requiresPrescription') as FormControl }
}
