import * as _ from "lodash";

export function changedFields<T>(updatedObject: T, object: T): any {
  let changedFields: any = {};

  for(const property in updatedObject) {
    if(!_.isEqual(updatedObject[property as keyof T], object[property as keyof T])) {
      changedFields[property] = updatedObject[property as keyof T];
    }
  }
  return changedFields;
}
