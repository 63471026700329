export enum CallStatus {
  ACCEPTED = 'ACCEPTED',
  CALLING = 'CALLING',
  CANCELED = 'CANCELED',
  CANCELED_PENDING = 'CANCELED_PENDING',
  CONNECTION_FAILED = 'CONNECTION_FAILED',
  CONNECTION_LOST = 'CONNECTION_LOST',
  ENDED = 'ENDED',
  ENDED_DOCTOR = 'ENDED_DOCTOR',
  ENDED_MANUAL = 'ENDED_MANUAL',
  ENDED_PATIENT = 'ENDED_PATIENT',
  EXPIRED = 'EXPIRED',
  FAILED_PENDING = 'FAILED_PENDING',
  PENDING = 'PENDING',
  PENDING_CALL = 'PENDING_CALL',
  REJECTED_AUTO = 'REJECTED_AUTO',
  REJECTED_MANUAL = 'REJECTED_MANUAL',
  TIMEOUTED = 'TIMEOUTED',
  WAITING_DOCTOR = 'WAITING_DOCTOR',
  WAITING_DOCTOR_FAILED = 'WAITING_DOCTOR_FAILED',
  WAITING_DOCTOR_ANSWER = 'WAITING_DOCTOR_ANSWER',
}
