import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from 'src/app/services/locale.service';
import { PatientService } from "src/app/services/patient.service";
import * as moment from 'moment';
import { NotificationsService } from 'src/app/services/notifications.service'
import { environment } from 'src/environments/environment';
import { Call } from 'src/app/models/call.model';
import { CONTROL_FREQUENCIES } from 'src/app/consts/control-frequencies.const';

interface Frequency {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']
})
export class MonitoringComponent implements OnInit {
  @Input()
  call: Call;

  @Input()
  disableControls = false;

  @Output()
  monitoringEnded = new EventEmitter<void>();

  finishDate: string;
  validationsMessage: string;
  formGroup: FormGroup;
  frequencies: Frequency[] = CONTROL_FREQUENCIES;

  constructor(
    private localeService: LocaleService,
    private patientService: PatientService,
    private notificationsService: NotificationsService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.updateFinishDate();
    if(this.disableControls) this.disableFields();

    this.formGroup.valueChanges.subscribe(() => {
      this.updateFinishDate();
    })
  }

  buildForm(): void {
    const firstFrequency = this.frequencies[0];

    this.formGroup = this.formBuilder.group({
      datetime: this.formBuilder.group({
        date: [moment().add(1, 'days').toDate(), [Validators.required]],
        time: ['08:00', [Validators.required]],
      }),
      frequency: [firstFrequency, [Validators.required]],
      iterations: [1, [Validators.required]],
    });
  }

  isDataValid(): boolean {
    this.validationsMessage = null;

    if(!this.frequency.value || !this.iterations.value || !this.date.value) {
      this.validationsMessage = "manual_call_form_required_fields";
      return false;
    }

    const now = moment();
    if(now.isAfter(this.startDate)) {
      this.validationsMessage = "manual_call_form_start_date_validation";
      return false;
    }

    return true;
  }

  submit(): void {
    if(!this.isDataValid()) return;

    const functionToExecute = "MANUAL_CALL_FORM";
    const newTask = {
      frequencyTime: this.frequency.value.value * Number(environment.DAY_TIMESPAN_IN_MILIS),
      functionToExecute,
      iterationsQuantity: this.iterations.value,
      startDate: this.startDate.toISOString(),
      functionParameters: { callId: this.call.id }
    }

    this.patientService.createTask(newTask).then(() => {
      this.monitoringEnded.emit();
      this.disableFields();
    }).catch((err) => {
      const data = {
        message: "monitoring_error",
        error: err.error || err,
        title: "default_warning_message",
        actions: [{
          function: () => undefined,
          message: "default_accept"
        }]
      }
      this.notificationsService.openErrorDialog(data, false);
    })
  }

  disableFields(): void {
    this.formGroup.disable();
  }

  updateFinishDate(): void {
    this.finishDate = this.startDate
      .add(this.iterations.value * this.frequency.value.value, "days")
      .subtract(this.frequency.value.value, "days")
      .format("DD-MM-YYYY HH:mm");
  }

  displayFrequencyFn = (frequency: Frequency): string => {
    return frequency?.viewValue ? this.localeService.getString(frequency?.viewValue) : '';
  }

  get startDate(): moment.Moment {
    const hours = parseInt(this.time.value?.split(':')[0]);
    const minutes = parseInt(this.time.value?.split(':')[1]);

    return moment(this.date.value)
      .startOf('day')
      .add(hours, "hours")
      .add(minutes, "minutes");
  }

  get datetime(): FormGroup { return this.formGroup.get('datetime') as FormGroup }
  get date(): FormControl<Date> { return this.formGroup.get('datetime.date') as FormControl }
  get time(): FormControl<string> { return this.formGroup.get('datetime.time') as FormControl }
  get frequency(): FormControl<Frequency> { return this.formGroup.get('frequency') as FormControl }
  get iterations(): FormControl<number> { return this.formGroup.get('iterations') as FormControl }
}
