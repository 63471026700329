import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from './services/locale.service';

@Pipe({
  name: 'localizedName'
})
export class LocalizedNamePipe implements PipeTransform {

  constructor(private localeService: LocaleService) { }  

  transform(value: any, locale: string = this.localeService.locale): string {
    if (typeof value === 'string') {
      return value;
    } else if(Object.keys(value).length === 0) {
      return "-";
    }
    
    let localizedName = value.localizedName[locale];
    if (!localizedName) {
      for (const [key] of Object.entries(value.localizedName)) {
        if (key.includes(locale)) {
          localizedName = value.localizedName[key];
        }
      }
    }
    // Adding spaces to break the line with CSS
    return !localizedName ? '' : localizedName.replace('/', ' / ');
  }

}
