import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Enrollment } from '../../../models/enrollment.model';
import { EnrollmentProvince } from 'src/app/models/enrollment-province.model';
import { EnrollmentType } from 'src/app/models/enrollment-type.model';
import { Country } from 'src/app/models/country.model';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { LocalePipe } from 'src/app/locale.pipe';
import { objectComparisonFunction } from 'src/app/utils/object-comparison.function';
import { FileExtensions } from 'src/app/enums/file-extensions.enum';

const { PNG, JPEG, JPG, PDF } = FileExtensions;

@Component({
  selector: 'app-enrollment-form',
  templateUrl: './enrollment-form.component.html',
  styleUrls: ['./enrollment-form.component.css']
})
export class EnrollmentFormComponent implements OnInit {
  @Output()
  onSubmitEvent = new EventEmitter<any>();

  @Input()
  formGroup!: FormGroup;

  @Input()
  enrollment?: Enrollment;

  @Input()
  enrollmentProvinces!: EnrollmentProvince[];

  @Input()
  enrollmentTypes!: EnrollmentType[];

  @Input()
  countries!: Country[];

  @Input()
  resetFormSubject?: Subject<void>;

  @ViewChild('formGroupDirective') private formGroupDirective!: FormGroupDirective;

  filteredEnrollmentProvinces!: EnrollmentProvince[];
  filteredEnrollmentTypes!: EnrollmentType[];

  fileUrl?: {fileName: string, url: string};

  supportedFileExtensions = [PNG, JPEG, JPG, PDF];

  constructor(
    private toastService: ToastService,
    private localePipe: LocalePipe,
  ) { }

  ngOnInit(): void {
    if(this.enrollment?.fileS3Key) {
      if(_.isString(this.enrollment.fileS3Key)) {
        this.fileUrl = { fileName: this.enrollment.fileS3Key.split('.com/')[1], url: this.enrollment.fileS3Key };
      } else {
        // File
        this.fileUrl = { fileName: (this.enrollment.fileS3Key as File).name, url: URL.createObjectURL(this.enrollment.fileS3Key) };
      }
    }

    this.cascadeFiltering();

    // Activate cascade filters for the first time
    this.country.updateValueAndValidity();
    this.province.updateValueAndValidity();

    this.resetFormSubject?.subscribe(() => { this.resetForm() });
  }

  onUploadFile($event: any): void {
    const file: File = $event.target.files[0];
    this.file.patchValue(file);
    this.fileUrl = { fileName: file.name, url: URL.createObjectURL(file) };
  }

  onRemoveFile(): void {
    this.file.patchValue(null);
    this.fileUrl = undefined;
  }

  submit(): void {
    const properties = [
      {value: 'country', text: 'documentation_enrollment_country'},
      {value: 'province', text: 'documentation_enrollment_province'},
      {value: 'type', text: 'documentation_enrollment_type'},
      {value: 'number', text: 'documentation_enrollment_code'},
      {value: 'expirationDate', text: 'documentation_enrollment_date'},
      {value: 'file', text: 'documentation_enrollment_file'}
    ];

    if(!this.formGroup.valid) {
      for(const property of properties) {
        const control = this.formGroup.controls[property.value];
        if(!_.isEmpty(control.errors)) {
          const message = this.localePipe.transform('default_required_field');
          const textField = this.localePipe.transform(property.text);
          this.toastService.openErrorToast(`${message} "${textField}"`);
          return;
        }
      }
    }

    this.onSubmitEvent.emit();
  }

  resetForm(): void {
    // The form is marked as submitted after reset is called, so we need to set a timeout to make this work
    setTimeout(() => this.formGroupDirective.resetForm({
      country: null,
      province: null,
      type: null,
      number: "",
      expirationDate: "",
      file: null,
    }), 0);

    this.fileUrl = undefined;
  }

  cascadeFiltering(): void {
    // Filter provinces
    this.country.valueChanges.subscribe(value => {
      if (value) {
        // selected value
        this.filterEnrollmentProvincesByCountry(value as Country);

        if(this.province.value) {
          const countryOfProvince = _.isObject(this.province.value.country) ? this.province.value.country.id : this.province.value.country;
          if(value.id !== countryOfProvince) this.province.patchValue(null);
        }
      } else {
        // value's empty
        this.filteredEnrollmentProvinces = [];
        this.filteredEnrollmentTypes = [];
      }
    });

    // Filter enrollment types
    this.province.valueChanges.subscribe(value => {
      if (value) {
        // selected value
        this.filterEnrollmentTypesByProvince(value as EnrollmentProvince);

        if(this.type.value) {
          const typeOfProvince = _.isObject(this.type.value.enrollmentProv) ? this.type.value.enrollmentProv.id : this.type.value.enrollmentProv;
          if(value.id !== typeOfProvince) this.type.patchValue(null);
        }
      } else {
        // value's empty
        this.filteredEnrollmentTypes = [];
      }
    });
  }

  filterEnrollmentProvincesByCountry(value: Country): void {
    this.filteredEnrollmentProvinces = this.enrollmentProvinces.filter((enrollmentProv: any) => {
      return enrollmentProv.country?.id === value.id;
    });
  }

  filterEnrollmentTypesByProvince(value: EnrollmentProvince): void {
    this.filteredEnrollmentTypes = this.enrollmentTypes.filter((enrollmentType: any) => {
      return enrollmentType.enrollmentProv?.id === value.id;
    });
  }

  objectComparisonFunction = objectComparisonFunction;

  displayProvince(enrollmentProvince: EnrollmentProvince): string {
    return (enrollmentProvince as any).province || enrollmentProvince.name;
  }

  get country(): FormControl { return this.formGroup.get('country') as FormControl }
  get province(): FormControl { return this.formGroup.get('province') as FormControl }
  get type(): FormControl { return this.formGroup.get('type') as FormControl }
  get number(): FormControl { return this.formGroup.get('number') as FormControl }
  get expirationDate(): FormControl { return this.formGroup.get('expirationDate') as FormControl }
  get file(): FormControl { return this.formGroup.get('file') as FormControl }
}
