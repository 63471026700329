<div class="container">
    <div class="section left">
        <div class="content">
            <h1 class="title">{{ label | locale }}</h1>
        </div>
    </div>

    <div class="section center">
        <div class="content">
            <ng-container [ngTemplateOutlet]="template"></ng-container>
        </div>
    </div>

    <div class="section right"></div>
</div>
