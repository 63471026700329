export enum CallResolutions {
  SOLVED = 'SOLVED',
  URGENT = 'URGENT',
  REFERRED = 'REFERRED',
  CONNECTION_FAILED = 'CONNECTION_FAILED',
  PATIENT_DISCHARGED = 'PATIENT_DISCHARGED',
  COULD_NOT_CONTACT = 'COULD_NOT_CONTACT',
  WRONG_PHONE = 'WRONG_PHONE',
  CONSULTATION_PREVIOUSLY_SOLVED = 'CONSULTATION_PREVIOUSLY_SOLVED',
  DISCONTINUE_MONITORING = 'DISCONTINUE_MONITORING',
}
