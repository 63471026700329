import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { SharedDataService } from './services/shared-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild("loaderDiv") loaderDiv: ElementRef;
  title = 'lad-call-form';
  token;
  callId;
  header;

  constructor(
    private sharedDataService: SharedDataService,
    private renderer: Renderer2,
    private router: Router,
    private loaderService: LoaderService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.registerIcons();
  }

  ngOnInit() {
    this.router.events.subscribe(async (event) => {
      if (event instanceof ActivationEnd) {
        this.token = event.snapshot.queryParams['token'];
        this.callId = event.snapshot.queryParams['call'];
        if(!environment.production) {
          const apiURL = event.snapshot.queryParams['api-url'];
          if(!_.isUndefined(apiURL)) this.sharedDataService.apiUrl = apiURL;
        }
        this.sharedDataService.setToken(this.token)
        this.sharedDataService.setCall(this.callId)
      }
    })
  }

  async ngAfterViewInit() {
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      if (status) {
        this.renderer.removeClass(this.loaderDiv.nativeElement, 'hideLoader');
        this.renderer.addClass(this.loaderDiv.nativeElement, 'showLoader');
      } else {
        this.renderer.removeClass(this.loaderDiv.nativeElement, 'showLoader');
        this.renderer.addClass(this.loaderDiv.nativeElement, 'hideLoader');
      }
    });
  }

  registerIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg')
    ).addSvgIcon(
      'picture-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/picture-icon.svg')
    ).addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg')
    ).addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg')
    ).addSvgIcon(
      'short-arrow-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/short-arrow-down.svg')
    ).addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg')
    ).addSvgIcon(
      'red-cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/red-cross.svg')
    ).addSvgIcon(
      'green-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green-check.svg')
    ).addSvgIcon(
      'arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right.svg')
    ).addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg')
    ).addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg')
    ).addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg')
    ).addSvgIcon(
      'app-store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/app-store.svg')
    ).addSvgIcon(
      'play-store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/play-store.svg')
    ).addSvgIcon(
      'create',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/create.svg')
    ).addSvgIcon(
      'local-hospital',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/local-hospital.svg')
    ).addSvgIcon(
      'library-books',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/library-books.svg')
    ).addSvgIcon(
      'call',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/call.svg')
    ).addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    ).addSvgIcon(
      'remove',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/remove.svg')
    ).addSvgIcon(
      'star-fill',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/star-fill.svg')
    ).addSvgIcon(
      'star-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/star-outline.svg')
    ).addSvgIcon(
      'pill',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pill.svg')
    ).addSvgIcon(
      'exams',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/exams.svg')
    ).addSvgIcon(
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/phone.svg')
    ).addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cancel.svg')
    ).addSvgIcon(
      'capsule',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/capsule.svg')
    ).addSvgIcon(
      'sheet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sheet.svg')
    ).addSvgIcon(
      'flat-trash-can',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/flat-trash-can.svg')
    ).addSvgIcon(
      'lab-results',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/lab-results.svg')
    ).addSvgIcon(
      'ultrasound',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ultrasound.svg')
    ).addSvgIcon(
      'xray',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/xray.svg')
    ).addSvgIcon(
      'resonance',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/resonance.svg')
    ).addSvgIcon(
      'electrocardiogram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/electrocardiogram.svg')
    ).addSvgIcon(
      'others',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/others.svg')
    ).addSvgIcon(
      'visibility',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/visibility.svg')
    ).addSvgIcon(
      'zoom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/zoom.svg')
    ).addSvgIcon(
      'test-tube',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/test-tube.svg')
    )
  }
}
