import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Call } from 'src/app/models/call.model';
import { MedicinePrescription } from 'src/app/models/medicine-prescription.model';
import { Medicine } from 'src/app/models/medicine.model';
import { MedicineService } from 'src/app/services/medicine.service';
import { autocompleteObjectValidator } from 'src/app/utils/autocomplete-validators.function';

@Component({
  selector: 'app-medicine-form',
  templateUrl: './medicine-form.component.html',
  styleUrls: ['./medicine-form.component.css']
})
export class MedicineFormComponent implements OnInit {
  @Input()
  call: Call;

  @Input()
  displaySubmitButton = true;

  @Output()
  onSelectMedicine = new EventEmitter<{ medicine: Medicine, index: number }>();

  @Output()
  onChangeQuantity = new EventEmitter<void>();

  @Output()
  onSubmitForm = new EventEmitter<MedicinePrescription>();

  @Output()
  validFormChange = new EventEmitter<boolean>();

  @Input()
  resetObservable: Observable<void>;

  @Input()
  formGroup: FormGroup;

  @Input()
  index: number;

  isMainForm: boolean;

  filteredMedicines: Observable<Medicine[]> = of([]);

  @ViewChild('form') ngForm!: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private medicineService: MedicineService,
  ) { }

  ngOnInit(): void {
    this.isMainForm = !this.formGroup;
    this.buildForm();
    this.subscribeToMedicineFormChanges();

    this.resetObservable?.subscribe(() => this.resetForm());
  }

  buildForm(): void {
    if(!this.formGroup) {
      this.formGroup = this.formBuilder.group({
        medicine: [null, [Validators.required, autocompleteObjectValidator()]],
        quantity: [1, [Validators.required, Validators.min(1), Validators.max(100)]],
        instructions: [null, [Validators.required]],
      });
    }

    this.quantity.valueChanges.subscribe(() => this.onChangeQuantity.emit());

    if(this.isMainForm) {
      this.formGroup.valueChanges.subscribe(() =>
        this.validFormChange.emit(this.formGroup.valid)
      );
    }
  }

  subscribeToMedicineFormChanges(): void {
    this.filteredMedicines = this.medicine.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      map(value => typeof value === 'string' ? value : value?.active),
      switchMap((val: string) => this.getFilterMedicines(val || ''))
    ) as unknown as Observable<Medicine[]>;
  }

  private getFilterMedicines(value: string): Medicine[] {
    const filterValue = value.toLowerCase();
    if(filterValue.length < 3) return [];
    return this.medicineService.get(this.call?.provider?.id, filterValue)
    .pipe(catchError(() => []));
  }

  displayMedicineFn = (medicine: Medicine): string => {
    return this.medicineService.getMedicineName(medicine);
  }

  submitForm(): void {
    this.validateForm();
    if(this.formGroup?.valid) {
      const medicinePrescription: MedicinePrescription = {
        medicine: this.medicine.value,
        quantity: this.quantity.value,
        instructions: this.instructions.value?.trim(),
      }
      this.onSubmitForm.emit(medicinePrescription);
      this.resetForm();
    }
  }

  resetMedicineControl(): void {
    this.resetFilteredMedicines();
    this.medicine.reset();
  }

  resetFilteredMedicines(): void {
    this.filteredMedicines = of([]);
    this.subscribeToMedicineFormChanges();
  }

  resetForm(): void {
    this.resetFilteredMedicines();
    this.ngForm.resetForm();
    this.formGroup.patchValue({ quantity: 1 });
  }

  validateForm(): void {
    Object.keys(this.formGroup.controls).forEach((key) =>
      this.formGroup.controls[key]?.updateValueAndValidity()
    );
  }

  onRemoveMedicine(): void {
    this.resetMedicineControl();
  }

  get medicine(): FormControl { return this.formGroup.get("medicine") as FormControl }
  get quantity(): FormControl { return this.formGroup.get("quantity") as FormControl }
  get instructions(): FormControl { return this.formGroup.get("instructions") as FormControl }
}
