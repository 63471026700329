import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DoctorService } from 'src/app/services/doctor.service';
import { SharedDataService } from '../services/shared-data.service';
import { Doctor } from '../models/doctor.model';
import { ActivationEnd, Router } from '@angular/router';
import { EnrollmentService } from '../services/enrollment.service';
import * as _ from 'lodash';
import { ToastService } from '../services/toast.service';
import { Enrollment, EnrollmentDTO } from '../models/enrollment.model';
import { LocaleService } from '../services/locale.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/components/dialog/dialog.component';
import { LocalePipe } from '../locale.pipe';
import { DoctorFileDataTypes } from '../enums/doctor-file-data-types.enum';
import { DoctorFileData } from '../models/doctor-file-data.model';
import { changedFields } from '../utils/changed-fields.function';
import { AppPaths } from '../enums/app-paths.enum';
import { FileExtensions } from '../enums/file-extensions.enum';

enum Documentation {
  ENROLLMENT = 'enrollment',
  MALPRACTICE_INSURANCE = 'malpracticeInsurance',
  REGISTER_SSS = 'registerSSS',
}

const { PNG, JPEG, JPG, PDF } = FileExtensions;
const { MALPRACTICE_INSURANCE, REGISTER_SSS } = Documentation;

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {
  formGroup!: FormGroup;
  doctor!: Doctor;
  documentation!: Documentation[];

  readonly Documentation = Documentation;
  supportedFileExtensions = [PNG, JPEG, JPG, PDF];

  constructor(
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private enrollmentService: EnrollmentService,
    private toastService: ToastService,
    private localeService: LocaleService,
    private dialog: MatDialog,
    private localePipe: LocalePipe,
    private title: Title,
  ) {
    this.router.events.subscribe(async (event) => {
      if (event instanceof ActivationEnd) {
        const documentation = event.snapshot.queryParams['documentation'];
        this.documentation = documentation?.split(',');
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.title.setTitle(this.localeService.getString('documentation_tab_title'));

    this.doctor = await this.doctorService.getMe();

    if(!this.doctor || _.isEmpty(this.documentation)) {
      this.router.navigate([AppPaths.MESSAGE], {state: {message: "malformed_url_error"}});
      return;
    }
    this.sharedDataService.setDoctorId(this.doctor.id);

    await this.assignFileData();

    this.buildForm();
  }

  async assignFileData(): Promise<void> {
    const doctorFileData = await this.doctorService.getFileData(this.doctor.id);

    const fileDataTypes = Object.values(Documentation);
    fileDataTypes.forEach(documentation => {
      this.doctor[documentation] = doctorFileData[documentation] || {};
    });
  }

  buildForm(): void {
    this.formGroup = this.fb.group({
      enrollment: this.fb.group({
        country: [null, [Validators.required]],
        province: [null, [Validators.required]],
        type: [null, [Validators.required]],
        number: ["", [Validators.required]],
        expirationDate: ["", [Validators.required]],
        file: [null, [Validators.required]],
      }),
      fileData: this.fb.array(this.documentationFormArray()),
    });
  }

  documentationFormArray(): FormControl[] {
    const formArray: FormControl[] = [];

    if(this.documentation.includes(MALPRACTICE_INSURANCE)) {
      const malpracticeInsurance = this.fb.control({
        fieldName: MALPRACTICE_INSURANCE,
        text: "documentation_malpractice_insurance",
        fileDataFormGroup: this.fb.group({
          name: [(this.doctor.malpracticeInsurance as DoctorFileData)?.name || null, [Validators.required]],
          expirationDate: [(this.doctor.malpracticeInsurance as DoctorFileData)?.expirationDate || null, [Validators.required]],
          fileS3Key: null,
          fileName: (this.doctor.malpracticeInsurance as DoctorFileData)?.fileName || null,
          url: [(this.doctor.malpracticeInsurance as DoctorFileData)?.url || null, [Validators.required]],
        }),
      });

      formArray.push(malpracticeInsurance);
    }

    if(this.documentation.includes(REGISTER_SSS)) {
      const registerSSS = this.fb.control({
        fieldName: REGISTER_SSS,
        text: "documentation_sss_register",
        fileDataFormGroup: this.fb.group({
          expirationDate: [(this.doctor.registerSSS as DoctorFileData)?.expirationDate || null, [Validators.required]],
          fileS3Key: null,
          fileName: (this.doctor.registerSSS as DoctorFileData)?.fileName || null,
          url: [(this.doctor.registerSSS as DoctorFileData)?.url || null, [Validators.required]],
        }),
      });

      formArray.push(registerSSS);
    }

    return formArray;
  }

  onUploadFile($event: any, formGroup: FormGroup): void {
    const file: File = $event.target.files[0];

    formGroup.get('fileS3Key')?.patchValue(file);
    formGroup.get('fileName')?.patchValue(file.name);
    formGroup.get('url')?.patchValue(URL.createObjectURL(file));
  }

  onRemoveFile(formGroup: FormGroup): void {
    formGroup.get('url')?.reset(null);
    formGroup.get('fileS3Key')?.reset(null);
    formGroup.get('fileName')?.reset(null);
  }

  enrollmentHasRequiredData(enrollment: Partial<Enrollment>): boolean {
    const properties = [
      {value: 'country', text: 'documentation_enrollment_country'},
      {value: 'enrollmentProv', text: 'documentation_enrollment_province'},
      {value: 'enrollmentType', text: 'documentation_enrollment_type'},
      {value: 'number', text: 'documentation_enrollment_code'},
      {value: 'expirationDate', text: 'documentation_enrollment_date'},
      {value: 'fileS3Key', text: 'documentation_enrollment_file'}
    ];

    for(const property of properties) {
      if(!enrollment[property.value]) {
        const message1 = this.localePipe.transform('default_required_field');
        const message2 = this.localePipe.transform('documentation_send_error_dialog_message_enrollment');
        const fieldText = this.localePipe.transform(property.text);

        const data = {
          message: `${message1} "${fieldText}" ${message2} ${enrollment.number} - ${enrollment.enrollmentType.name} - ${enrollment.country.name}`,
          title: "default_wait_message",
          localizeMessage: false,
          actions: [{
            function: () => {},
            message: "default_accept"
          }]
        }

        this.dialog.open(DialogComponent, {
          width: "400px",
          panelClass: 'dark-dialog',
          disableClose: false,
          data,
        });
        return false;
      }
    }

    return true;
  }

  async submitEnrollment(): Promise<(() => Promise<any>)[]> {
    const incomingEnrollments = this.sharedDataService.enrollments;
    const existingEnrollments = await this.enrollmentService.getByDoctor();

    if(_.isEmpty(incomingEnrollments)) {
      this.toastService.openErrorToast("documentation_send_error_no_enrollments");
      return;
    }

    const promises: (() => Promise<any>)[] = [];

    for(const enrollment of existingEnrollments) {
      // Check deleted
      const existInIncomingEnrollments = _.find(incomingEnrollments, (el => enrollment.id === el.id));
      if(!existInIncomingEnrollments) promises.push(() => this.enrollmentService.delete(enrollment.id));
    }

    for(const enrollment of incomingEnrollments) {
      if(!enrollment.id) {
        const enrollmentToCreate: Partial<EnrollmentDTO> = {
          ...enrollment,
          country: enrollment.country?.id,
          enrollmentProv: enrollment.enrollmentProv?.id,
          enrollmentType: enrollment.enrollmentType?.id
        }
        // Enrollment created
        promises.push(() => this.enrollmentService.create({...enrollmentToCreate, fileS3Key: undefined})
          .then(async enrollmentCreated => {
            if(enrollment.fileS3Key) {
              const formData = new FormData();
              formData.set("fileS3Key", enrollment.fileS3Key);
              await this.enrollmentService.uploadFile(enrollmentCreated.id, formData);
            }
          })
        );
      } else {
        // Update enrollment
        const existingEnrollment = _.find(existingEnrollments, (el) => enrollment.id === el.id);

        if(!this.enrollmentHasRequiredData(enrollment)) return;

        const updatedEnrollment: Partial<EnrollmentDTO> = {
          ...(enrollment.country.id !== existingEnrollment.country.id && {country: enrollment.country?.id}),
          ...(enrollment.enrollmentProv.id !== existingEnrollment.enrollmentProv.id && {enrollmentProv: enrollment.enrollmentProv?.id}),
          ...(enrollment.enrollmentType.id !== existingEnrollment.enrollmentType.id  && {enrollmentType: enrollment.enrollmentType?.id}),
          ...(enrollment.number !== existingEnrollment.number && {number: enrollment.number}),
          ...(enrollment.expirationDate !== existingEnrollment.expirationDate && {expirationDate: enrollment.expirationDate}),
          ...(enrollment.fileS3Key !== existingEnrollment.fileS3Key && enrollment.fileS3Key === null && {fileS3Key: null}),
        }

        if(!_.isEmpty(updatedEnrollment)) {
          // Enrollment updated
          updatedEnrollment.verified = false;
          promises.push(() => this.enrollmentService.update(enrollment.id, updatedEnrollment));
        } else {
          // Put verified in false anyways
          promises.push(() => this.enrollmentService.update(enrollment.id, {verified: false}));
        }

        if(enrollment.fileS3Key !== existingEnrollment.fileS3Key) {
          const formData = new FormData();
          formData.set("fileS3Key", enrollment.fileS3Key);
          promises.push(() => this.enrollmentService.uploadFile(enrollment.id, formData));
        }
      }
    }

    return promises;
  }

  fileDataHasRequiredData(formGroup: FormGroup, fileDataType: DoctorFileDataTypes): boolean {
    const properties = {
      name: 'documentation_file_data_name',
      expirationDate: 'documentation_enrollment_date',
      url: 'documentation_file_data_file'
    };

    if(formGroup.invalid) {
      for(const fieldName of Object.keys(formGroup.controls)) {
        const control = formGroup.controls[fieldName];

        if(control.invalid) {
          const message1 = this.localePipe.transform('default_required_field');

          let message2 = "";
          if(fileDataType === DoctorFileDataTypes.MALPRACTICE_INSURANCE) {
            message2 = this.localePipe.transform('documentation_send_error_dialog_message_malpractice_insurance');
          } else if(fileDataType === DoctorFileDataTypes.REGISTER_SSS) {
            message2 = this.localePipe.transform('documentation_send_error_dialog_message_sss_register');
          }

          const fieldText = this.localePipe.transform(properties[fieldName]);

          const data = {
            message: `${message1} "${fieldText}" ${message2}`,
            title: "default_wait_message",
            localizeMessage: false,
            actions: [{
              function: () => {},
              message: "default_accept"
            }]
          }

          this.dialog.open(DialogComponent, {
            width: "400px",
            panelClass: 'dark-dialog',
            disableClose: false,
            data,
          });
          return false;
        }
      }
    }

    return true;
  }

  uploadFileData(): (() => Promise<any>)[] {
    const promises: (() => Promise<any>)[] = [];

    const fileDataTypes = Object.values(DoctorFileDataTypes);
    for(const fileDataType of fileDataTypes) {
      if(!this.documentation.includes(fileDataType as any)) continue;

      const formGroup: FormGroup = _.find(this.fileData.value, val => fileDataType === val.fieldName).fileDataFormGroup;
      const fileData: DoctorFileData = formGroup.value;
      const formData = new FormData();

      if(!this.fileDataHasRequiredData(formGroup, fileDataType)) return;

      // Check if previous value exists
      if(_.isEmpty(this.doctor[fileDataType])) {
        // Create
        if(fileData.name) formData.append("name", fileData.name);
        if(fileData.expirationDate) formData.append("expirationDate", fileData.expirationDate);
        formData.append("doctor", this.doctor.id);
        formData.append("type", fileDataType);
        formData.append("fileS3Key", fileData.fileS3Key);
        formData.append("verified", "false");

        promises.push(() => this.doctorService.createFileData(this.doctor.id, formData));

      } else {
        // Update
        const fileDataChangedFields = changedFields(fileData, this.doctor[fileDataType]);

        if((this.doctor[fileDataType] as DoctorFileData).url === formGroup.get('url')?.value) {
          // No file changes
          delete fileDataChangedFields.fileS3Key;
        }

        if(!_.isEmpty(fileDataChangedFields)) {
          for(const property in fileDataChangedFields) {
            formData.append(property, typeof fileDataChangedFields[property] === 'string' ? fileDataChangedFields[property].trim() : fileDataChangedFields[property]);
          }
        }

        formData.append("verified", "false");
        promises.push(() => this.doctorService.updateFileData((this.doctor[fileDataType] as DoctorFileData)!.id, formData));
      }
    }

    return promises;
  }

  async submit(): Promise<void> {
    let promises: (() => Promise<any>)[] = [];

    if(this.documentation.includes(Documentation.ENROLLMENT)) {
      const enrollmentPromises = await this.submitEnrollment();
      if(!_.isEmpty(enrollmentPromises)) promises = [...promises, ...enrollmentPromises];
      else return;
    }

    if(
      this.documentation.includes(Documentation.MALPRACTICE_INSURANCE) ||
      this.documentation.includes(Documentation.REGISTER_SSS)
    ) {
      const fileDataPromises = this.uploadFileData();
      if(!_.isEmpty(fileDataPromises)) promises = [...promises, ...fileDataPromises];
      else return;
    }

    try {
      if(!_.isEmpty(promises)) {
        await Promise.all(promises.map(promise => promise()));

        await this.doctorService.sendNotificationSubmittedRequestedDocumentation(this.doctor.id);

        this.router.navigate([AppPaths.MESSAGE], {state: { message: "documentation_send_success_message" }});
      }
    } catch (error) {
      this.toastService.openErrorToast(error?.error?.message);
    }
  }

  hasProperty(obj: any, name: string): boolean {
    return obj.hasOwnProperty(name);
  }

  get enrollment(): FormGroup { return this.formGroup.get('enrollment') as FormGroup }
  get fileData(): FormGroup { return this.formGroup.get('fileData') as FormGroup }
}
