import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { LocalePipe } from '../locale.pipe';
import { ToastComponent } from '../shared/components/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private snackBar: MatSnackBar,
    private localePipe: LocalePipe,
  ) { }

  openToast(message: string, config: MatSnackBarConfig, type: 'error' | 'success'): MatSnackBarRef<ToastComponent> {
    message = this.localePipe.transform(message);
    config = { ...config, duration: 4000, data: { message, type } };
    return this.snackBar.openFromComponent(ToastComponent, config);
  }

  openErrorToast(message = 'error_default', config?: MatSnackBarConfig<TextOnlySnackBar>): MatSnackBarRef<ToastComponent> {
    config = { ...config, panelClass: ['toast-error'] }
    return this.openToast(message, config, 'error');
  }

  openSuccessToast(message = 'success_default', config?: MatSnackBarConfig<TextOnlySnackBar>): MatSnackBarRef<ToastComponent> {
    config = { ...config, panelClass: ['toast-success'] }
    return this.openToast(message, config, 'success');
  }
}
