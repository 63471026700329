<form *ngIf="formGroup" [formGroup]="formGroup">
    <span *ngIf="patientHasRepeatedAbsenteeism" class="warn-label">
        {{ 'patient_repeated_absenteeism' | locale }}
    </span>

    <app-medical-order-form
        [call]="call"
        [practicesToOmitFromListSubject]="practicesToOmitFromListSubject"
        [resetObservable]="resetSubject.asObservable()"
        [enableMedicalOrderDescription]="enableMedicalOrderDescription"
        [enableRestIndicationSubject]="enableRestIndicationSubject"
        (onSubmitForm)="addMedicalOrder($event)"
        (formChangeEvent)="checkIfMedicalOrderFormHasFilledFields()"
        (selectPracticeEvent)="onSelectPractice($event)"
        (removePracticeEvent)="onRemovePractice($event)">
    </app-medical-order-form>

    <ng-container *ngIf="medicalOrders.controls?.length > 0">
        <h2>{{ 'medical_order_other' | locale }}</h2>
        <mat-divider></mat-divider>

        <app-item
            *ngFor="let medicalOrderFormGroup of medicalOrders?.controls; index as index"
            [itemText]="getUploadedMedicalOrderTitle(medicalOrderFormGroup)"
            [south]="true"
            iconName="sheet"
            color="bg-dark"
            class="medium-height-icon"
            [collapsable]="true"
            [itemDetailTemplate]="medicalOrderTemplate"
            [data]="medicalOrderFormGroup"
            [index]="index"
            [displayTrashButton]="!medicalOrderFormGroup.value.id"
            (removeEvent)="onRemoveMedicalOrder($event)">
        </app-item>

        <ng-template #medicalOrderTemplate let-open="openCollapsable" let-medicalOrderFormGroup="data" let-index="index">
            <div *ngIf="open" class="collapsable">
                <app-medical-order-form
                    [call]="call"
                    [practicesToOmitFromListSubject]="practicesToOmitFromListSubject"
                    [formGroup]="medicalOrderFormGroup"
                    [index]="index"
                    [displayInstructionCheckbox]="false"
                    [enableMedicalOrderDescription]="enableMedicalOrderDescription"
                    (selectPracticeEvent)="onSelectPractice($event)"
                    (removePracticeEvent)="onRemovePractice($event)">
                </app-medical-order-form>
            </div>
        </ng-template>
    </ng-container>

    <div class="actions">
        <button
            mat-flat-button
            color="primary"
            [disabled]="!medicalOrders.valid || 
                (medicalOrders?.length === 0 && !medicalOrderFormHasFilledFields) || 
                (medicalOrders?.length === call.medicalOrders?.length && !medicalOrderFormHasFilledFields)"
            (click)="submit()">
            {{ 'medical_order_send' | locale }}
            <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
        </button>

        <button
            mat-flat-button
            color="primary"
            (click)="skipStep()">
            {{ 'medical_order_skip' | locale }}
            <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
        </button>
    </div>
</form>