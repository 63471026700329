import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../shared/components/dialog/dialog.component';
import { DialogData } from '../models/dialog-data.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  dialogRef!: MatDialogRef<DialogComponent>;

  constructor(private dialog: MatDialog) { }

  openErrorDialog(params: Partial<DialogData>, disableClose: boolean): void {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      panelClass: 'dark-dialog',
      disableClose: disableClose,
      data: params
    });
  }

  duplicatedMedicineMessage(): void {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      panelClass: 'dark-dialog',
      data: {
        title: "default_warning_message",
        message: "prescription_duplicate_medicine",
        actions: [{
          function: () => undefined,
          message: "close"
        }]
      }
    })
  }

}
