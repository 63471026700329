<mat-label *ngIf="label" [isRequired]="control">{{ label | locale }}</mat-label>
<mat-form-field color="accent">
    <input
        matInput
        [formControlName]="controlName"
        [matAutocomplete]="auto"
        [placeholder]="placeholder | locale" />
    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="optionSelectedEvent.emit($event.option.value)">
        <mat-option
            *ngFor="let item of items | async"
            [value]="item">
            <mat-option-text>{{ displayFn(item) }}</mat-option-text>
        </mat-option>
    </mat-autocomplete>

    <mat-icon
        *ngIf="!control.disabled"
        matIconSuffix
        class="select-suffix"
        (click)="handleClickIcon()"
        [ngClass]="{'icon-available-delete': control?.value}"
        [svgIcon]="control?.value ? 'cancel' : 'short-arrow-down'">
    </mat-icon>

    <mat-error>{{ control | errorMessage }}</mat-error>
</mat-form-field>
