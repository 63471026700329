import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateAdapter } from '@angular/material/core';

interface Translation {
	[key: string]: string
}

@Injectable({
	providedIn: 'root'
})
export class LocaleService {

	translations: { [key: string]: Translation } = {};
	locale: string;
	defaultLocale = 'es-ar';
	supportedLocales = ['es-ar', 'es', 'es-co', 'pt-br', 'pt', 'en', 'en-us'];
	useDefault = false;
	now = Date.now();

	constructor(private http: HttpClient, private dateAdapter: DateAdapter<Date>) { }

	async use(browserLocale: string): Promise<Translation> {
		this.dateAdapter.setLocale(browserLocale.split('-')[0].toLowerCase());
		if(this.supportedLocales.includes(browserLocale)) {
			this.locale = browserLocale;
		} else {
			console.log(browserLocale + " not supported, using default: " + this.defaultLocale);
			this.locale = this.defaultLocale;
			this.useDefault = true;
		}

		return new Promise<Translation>((resolve) => {
			this.http.get<Translation>(`/assets/i18n/${this.locale}.json?v=${this.now}`).subscribe(
				translation => {
					this.translations[this.locale] = Object.assign({}, translation || {});
					resolve(this.translations[this.locale]);
				},
				() => {
					this.translations[this.locale] = {};
					resolve(this.translations[this.locale]);
				}
			);
		});
	}

	async getStringByLocale(key: string, locale: string): Promise<string> {
		if(!this.translations[locale]) {
			this.translations[locale] = await this.http.get<Translation>(`assets/i18n/${locale}.json?v=${this.now}`).toPromise();
		}

		return this.translations[locale][key];
	}

	getString(key: string): string {
		return this.translations[this.locale][key];
	}

	getLocale(){
		return this.locale || this.defaultLocale;
	}

	usingDefault(){
		return this.useDefault;
	}

	public getHeaderLocale(): string {
		//We send only language headers thar are accepted by the API
		//That is because we use sails i18n to translate messages, to see documentation, visit https://0.12.sailsjs.com/documentation/concepts/internationalization
		switch (this.locale) {
			case 'pt-br':
			case 'pt':
				return 'pt-BR';
			case 'es-co':
				return 'es-CO';
			case 'en':
			case 'en-us':
				return 'en-US'
			default:
				return 'es-AR';
		}
	}

	getLadHeader(): string {
		switch (this.getLocale()) {
			case 'pt':
			case 'pt-br':
				return 'assets/lad_header_primary_pt.svg';
			case 'en':
			case 'en-us':
				return 'assets/lad_header_primary_en.svg';
			default:
				return 'assets/lad_header_primary_es.svg';
		}
	}
}
