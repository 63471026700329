<mat-label *ngIf="label" [isRequired]="control">{{ label | locale }}</mat-label>
<mat-form-field>
    <input
        matInput
        [matDatepicker]="picker"
        [formControlName]="controlName"
        [placeholder]="placeholder ? (placeholder | locale) : ''"
        autocomplete="off"
        (dateChange)="onDateChangeEvent.emit($event)">
    <mat-datepicker-toggle matIconSuffix [for]="picker">
        <mat-icon
            matDatepickerToggleIcon
            svgIcon="calendar">
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-error>{{ control | errorMessage }}</mat-error>
</mat-form-field>
