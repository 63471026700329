import { Injectable } from '@angular/core';
import { LocaleService } from './locale.service';
import { RequestService } from './request.service';
import { Cie10 } from '../models/cie10.model';
import { Diagnosis } from '../models/diagnosis.model';
@Injectable({
  providedIn: 'root'
})
export class DiagnosisService {
  list: Cie10[] = [];

  constructor(
    private requestService: RequestService,
    private localeService: LocaleService,
  ) { }

  async getCie10(): Promise<Cie10[]> {
    const response: {version: number} = await this.requestService.get('/cie10/version');
    const version = response.version?.toString();
    const locale = this.localeService.getLocale();
    const languageSuffix = this.getLocaleSuffix(locale)
    const versionFromStorage = this.getCie10VersionFromStorage(languageSuffix);

    if(versionFromStorage === version) return this.getCie10FromStorage(languageSuffix);
    return this.getCie10FromApi(languageSuffix).then(cie10 => {
      this.setCie10InStorage(languageSuffix, version, cie10);
      return cie10;
    })
  }

  getCie10FromStorage(languageSuffix: string): Cie10[] {
    const localizedCie10 = localStorage.getItem("cie10_" + languageSuffix)
    if(localizedCie10){
      this.list = JSON.parse(localizedCie10)
      return this.list;
    }
  }

  async getCie10FromApi(languageSuffix: string): Promise<Cie10[]> {
    const data = await this.requestService.get('/cie10?locale=' + languageSuffix);

    Object.entries(data).forEach(e => {
      this.list.push(
        {
          code: e[0],
          text: e[1],
          searchValue: e[0] + ' - ' + e[1]
        } as Cie10
      );
    });

    return this.list;
  }

  getCie10VersionFromStorage(languageSuffix: string): string {
    return localStorage.getItem("cie10_version_" + languageSuffix)
  }

  setCie10InStorage(languageSuffix: string, version: string, cie10: Cie10[]): void {
    localStorage.setItem("cie10_version_" + languageSuffix, version)
    localStorage.setItem("cie10_" + languageSuffix, JSON.stringify(cie10))
  }

  getByCode(code: string): string {
    const value = this.list.find(diagnosis => diagnosis.code == code)
    return value?.text || '';
  }

  create(diagnosis: Partial<Diagnosis>): Promise<Diagnosis> {
    return this.requestService.post('/diagnosis', diagnosis)
  }

  private getLocaleSuffix(language: string): string {
		switch (language) {
      case 'pt-br':
      case 'pt':
        return 'pt';
      case 'en':
      case 'en-us':
        return 'en'
      default:
        return 'es';
		}
	}
}
