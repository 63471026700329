import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent {
  state: { message: string; };

  constructor(
    private router: Router,
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.state = navigation.extras.state as { message: string };
  }

}
