import { MedicalExamTypes } from '../enums/medical-exam-types.enum';

export const MEDICAL_EXAM_TYPES = [
  {
    value: MedicalExamTypes.LABORATORY_RESULTS,
    text: 'medical_exams_lab_results',
    icon: 'lab-results',
    color: '#FF4D7C',
  },
  {
    value: MedicalExamTypes.ULTRASOUND,
    text: 'medical_exams_ultrasound',
    icon: 'ultrasound',
    color: '#29293B',
  },
  {
    value: MedicalExamTypes.XRAY,
    text: 'medical_exams_xray',
    icon: 'xray',
    color: '#7878F7',
  },
  {
    value: MedicalExamTypes.RESONANCE,
    text: 'medical_exams_resonance',
    icon: 'resonance',
    color: '#D8D8D8',
  },
  {
    value: MedicalExamTypes.ELECTROCARDIOGRAM,
    text: 'medical_exams_electrocardiogram',
    icon: 'electrocardiogram',
    color: '#FF4D7C',
  },
  {
    value: MedicalExamTypes.OTHER,
    text: 'medical_exams_others',
    icon: 'others',
    color: '#C5FF00',
  },
];
