import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/dialog-data.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  errorMessage?: string;
  actions: { function: () => any, message: string }[];
  title?: string;
  localizeMessage?: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogComponent>,
  ) { }

  ngOnInit(): void {
    this.errorMessage = this.data.error ? this.data.error.message : "";
    this.actions = this.data.actions;
    this.title = this.data.title;
    this.localizeMessage = !(this.data.localizeMessage === false);
  }

  close(callback: () => any): void {
    this.dialogRef.close();
    if(callback) callback();
  }
}
