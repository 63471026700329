<div mat-dialog-header>
    <h1 *ngIf="title" mat-dialog-title>{{ title | locale }}</h1>
</div>

<mat-dialog-content>
    <p class="message">{{ localizeMessage ? (data.message | locale) : data.message }}</p>
    <p class="message" *ngIf="errorMessage">Error: {{ errorMessage }}</p>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        *ngIf="data.canCancel"
        mat-flat-button
        class="low no-fill"
        color="primary"
        (click)="close()">
        {{ data.closeMessage ? (data.closeMessage | locale) : "close" | locale }}
    </button>
    <ng-container *ngFor="let action of actions">
        <button
            mat-flat-button
            *ngIf="action"
            class="low"
            color="primary"
            (click)="close(action.function)">
            {{ action.message | locale }}
        </button>
    </ng-container>
</mat-dialog-actions>
