<ng-container *ngIf="patientCalls && patientCalls.data?.length > 0; else noData">
    <div class="button-container">
        <button
            mat-flat-button
            color="primary"
            class="low"
            (click)="prevPage()"
            [disabled]="index === 0">
            <mat-icon svgIcon="arrow-right" class="west"></mat-icon>
            {{ 'call_previous' | locale }}
        </button>
        <button
            mat-flat-button
            color="primary"
            class="low"
            (click)="nextPage()"
            [disabled]="index + 1 === patientCalls.data?.length">
            {{ 'call_next' | locale }}
            <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
        </button>
    </div>

    <div class="container">
        <app-medical-record
            *ngIf="patientCalls.data[index] as patientCall"
            [call]="patientCall"
            [index]="index"
            [totalCalls]="patientCalls.total"
            [diagnosisList]="diagnosisList">
        </app-medical-record>
    </div>
</ng-container>

<ng-template #noData>
    <div class="no-data-container">
        <span>{{ 'medical_records_empty_message' | locale }}</span>
    </div>
</ng-template>
  