import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import * as _ from 'lodash';
import { displayItemName } from 'src/app/utils/display-fields.utils';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
})
export class SelectComponent {
  @Output()
  selectedOption = new EventEmitter<any>();

  @Input()
  label!: string;

  @Input()
  form!: FormGroup;

  @Input()
  controlName!: string;

  @Input()
  placeholder!: string;

  @Input()
  items: any[] | null = [];

  @Input()
  displayValue: (item: any) => string = displayItemName;

  objectComparisonFunction(option: any, value: any): boolean {
    return (
      _.isEqual(option, value) ||
      _.isEqual(option?.id, value) ||
      (!_.isUndefined(option?.id) && !_.isUndefined(value?.id) && _.isEqual(option?.id, value?.id))
    );
  }

  get control(): FormControl { return this.form?.get(this.controlName) as FormControl }
}
