import { Component, Input, OnInit } from '@angular/core';
import { Call } from 'src/app/models/call.model';
import { Cie10 } from 'src/app/models/cie10.model';
import { CallService } from 'src/app/services/call.service';
import { DiagnosisService } from 'src/app/services/diagnosis.service';

@Component({
  selector: 'app-medical-records',
  templateUrl: './medical-records.component.html',
  styleUrls: ['./medical-records.component.css']
})
export class MedicalRecordsComponent implements OnInit {
  @Input()
  patientCalls!: {data: Call[], total: number};

  @Input()
  call!: Call;

  diagnosisList: Cie10[] = [];
  skip = 0;
  limit = 10;
  index = 0;

  constructor(
    private callService: CallService,
    private diagnosisService: DiagnosisService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getCie10();
  }

  getPatientCalls(): void {
    this.callService.getPatientCalls(this.call.id, this.skip, this.limit)
    .then((response: Call[]) => {
      this.patientCalls.data = [...this.patientCalls.data, ...response];
    })
    .catch(() => this.patientCalls = {data: [], total: 0});
  }

  async getCie10(): Promise<void> {
    this.diagnosisList = await this.diagnosisService.getCie10();
  }

  prevPage(): void {
    if (this.index > 0) this.index--;
  }

  nextPage(): void {
    if (this.index + 1 < this.patientCalls.data.length) this.index++;
    this.nextPageChanged();
  }

  nextPageChanged(): void {
    if(this.index + 1 === (this.skip + this.limit)) {
      this.skip = this.index + 1;
      this.getPatientCalls();
    }
  }
}
