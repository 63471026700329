export enum FileExtensions {
  JPG = '.jpg',
  PNG = '.png',
  PDF = '.pdf',
  XLSX = '.xlsx',
  GIF = '.gif',
  JPEG = '.jpeg',
  CSS = '.css',
  BMP = '.bmp',
}
