<div class="container" *ngIf="doctor && formGroup">
    <div class="section-container">
        <div class="section left">
            <div class="content">
                <h1 class="title">{{ 'documentation_title' | locale }}</h1>
            </div>
        </div>

        <div class="section center">
            <div class="content">
                <div class="white-container">
                    <h1 class="title">{{ doctor.name }}</h1>
                    <h2 class="title-accent">{{ 'documentation_card_subtitle' | locale }}</h2>
                    <p class="card-content">{{ 'documentation_card_content' | locale }} <b>altamedico@llamandoaldoctor.com</b></p>
                </div>

                <app-enrollment
                    *ngIf="documentation.includes(Documentation.ENROLLMENT)"
                    [formGroup]="enrollment">
                </app-enrollment>

                <ng-container *ngFor="let control of fileData.value; let i = index">
                    <h2 class="title-accent" style="margin: 2rem 0 1rem;">{{ control.text | locale }}</h2>

                    <form [formGroup]="control.fileDataFormGroup">
                        <ng-container *ngIf="hasProperty(control.fileDataFormGroup.controls, 'name')">
                            <mat-label>{{ 'documentation_file_data_name' | locale }}</mat-label>
                            <mat-form-field>
                                <input
                                    matInput
                                    formControlName="name"
                                    [placeholder]="'documentation_file_data_name_placeholder' | locale"
                                />
                                <mat-error>{{ control.fileDataFormGroup.get('name') | errorMessage }}</mat-error>
                            </mat-form-field>
                        </ng-container>

                        <app-datepicker
                            label="documentation_enrollment_date"
                            [form]="formGroup"
                            controlName="expirationDate"
                            placeholder="documentation_enrollment_date_placeholder">
                        </app-datepicker>

                        <app-file-input
                            [files]="control.fileDataFormGroup.value.fileName ? [ control.fileDataFormGroup.value ] : []"
                            [supportedExtensions]="supportedFileExtensions"
                            (uploadFileEvent)="onUploadFile($event, control.fileDataFormGroup)"
                            (removeFileEvent)="onRemoveFile(control.fileDataFormGroup)">
                        </app-file-input>
                    </form>
                </ng-container>

                <button
                    type="submit"
                    mat-flat-button
                    color="primary"
                    (click)="submit()">
                    {{ 'default_send' | locale }}
                    <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
                </button>
            </div>
        </div>

        <div class="section right"></div>
    </div>
</div>
