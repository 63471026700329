import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { SharedDataService } from './shared-data.service';
import { Enrollment, EnrollmentDTO } from '../models/enrollment.model';
import { EnrollmentType } from '../models/enrollment-type.model';
import { EnrollmentProvince } from '../models/enrollment-province.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {
  baseApiURL = '/enrollment';

  constructor(
    private requestService: RequestService,
    private sharedDataService: SharedDataService,
  ) { }

  getProvinces(): Promise<EnrollmentProvince[]> {
    return this.requestService.get('/enrollmentProv?sort=name ASC');
  }

  getTypes(): Promise<EnrollmentType[]> {
    return this.requestService.get('/enrollmentType?sort=name ASC');
  }

  getByDoctor(): Promise<Enrollment[]> {
    const doctorId = this.sharedDataService.getDoctorId();
    return this.requestService.get(`${this.baseApiURL}/byDoctor/${doctorId}?sort=name ASC`)
  }

  create(enrollment: Partial<EnrollmentDTO>): Promise<Enrollment> {
    return this.requestService.post(this.baseApiURL, enrollment);
  }

  update(id: string, enrollment: Partial<EnrollmentDTO>): Promise<Enrollment> {
    return this.requestService.put(`${this.baseApiURL}/${id}`, enrollment);
  }

  delete(id: string) {
    return this.requestService.delete(`${this.baseApiURL}/${id}`);
  }

  uploadFile(enrollmentId: string, body: FormData): Promise<Enrollment> {
    return this.requestService.put(`${this.baseApiURL}/${enrollmentId}/uploadFiles`, body);
  }

  checkDuplicatedEnrollments(enrollment: Partial<Enrollment>): Partial<Enrollment>[] {
    return _.filter(this.sharedDataService.enrollments, (el) =>
      enrollment.country?.id === el.country?.id &&
      enrollment.enrollmentType?.id === el.enrollmentType?.id &&
      enrollment.number.toString() === el.number.toString()
    );
  }

}
