<div class="container">
    <ng-container *ngIf="files.length > 0; else noFiles">
        <div class="file-item" *ngFor="let file of files">
            <a
                [ngClass]="{'disabled': !file.url}"
                (click)="openFile(file.url)"
            >{{ file.fileName }}</a>

            <button mat-icon-button (click)="removeFileEvent.emit(file.fileName)">
                <mat-icon svgIcon="trash"></mat-icon>
            </button>
        </div>
    </ng-container>

    <ng-template #noFiles>
        <div class="no-files">
            <h3 class="no-files-uploaded">{{ 'no_files_uploaded' | locale }}</h3>
            <h3 class="supported-files">{{ 'files_supported' | locale }}: {{ supportedExtensions.join(", ").toUpperCase() }}</h3>
        </div>

    </ng-template>

    <div style="text-align: center;">
        <input
            type="file"
            #fileInput
            (change)="uploadFileEvent.emit($event)"
            style="display: none"
            [accept]="supportedExtensions.join(',')"
        />
        <button
            type="button"
            mat-flat-button
            class="low file"
            color="accent"
            (click)="fileInput.click()">
            {{ 'choose_file_button' | locale }}
        </button>
    </div>
</div>
