import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fillArray'
})
export class FillArrayPipe implements PipeTransform {

  transform(value: number): number[] {
    const res = [];
    for (let i = 1; i <= value; i++) {
      res.push(i);
    }
    return res;
  }
}
