import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { LocaleService } from 'src/app/services/locale.service';

@Pipe({
  name: 'errorMessage',
  pure: false
})
export class ErrorMessagePipe implements PipeTransform {

  constructor(
    private localeService: LocaleService,
  ) {}

  transform(control: AbstractControl): string {
    if (control && control.errors) {
      const errors = control.errors;
      const errorMessages = Object.keys(errors).map(key => this.getErrorMessageByKey(key, errors[key]));
      return errorMessages.join(', ');
    }

    return '';
  }

  private getErrorMessageByKey(key: string, value: any): string {
    let text = '';

    switch (key) {
      case 'required':
        text = this.localeService.getString('required');
        break;
      case 'min':
        text = this.localeService.getString('min_value_error')
          .replace(new RegExp('__VALUE__', 'g'), value.min)
        break;
      case 'max':
        text = this.localeService.getString('max_value_error')
          .replace(new RegExp('__VALUE__', 'g'), value.max)
        break;
      case 'invalidTimeFormat':
        text = this.localeService.getString('time_format');
        break;
      case 'maxlength': {
        const { actualLength, requiredLength } = value;
        text = this.localeService.getString('max_length_error');
        text = text.concat(` ${actualLength}/${requiredLength}`);
        break;
      }
      default:
        break;
    }

    return text;
  }
}
