<form [formGroup]="formGroup">
    <h2 class="title">{{ 'manual_call_form_title' | locale }}</h2>

    <div formGroupName="datetime" class="dates">
        <div class="date">
            <mat-label [isRequired]="date">{{ "date" | locale }}</mat-label>
            <app-datepicker
                [form]="datetime"
                [formGroup]="datetime"
                controlName="date">
            </app-datepicker>
        </div>

        <div class="time">
            <mat-label [isRequired]="time">{{ "time" | locale }}</mat-label>
            <mat-form-field>
                <input
                    matInput
                    formControlName="time"
                    placeholder="hh:mm"
                    [ngxTimepicker]="fullTime"
                    [format]="24"
                    readonly
                />
                <ngx-material-timepicker #fullTime></ngx-material-timepicker>
            </mat-form-field>
        </div>
    </div>

    <app-select
        label="manual_call_form_frequency"
        [form]="formGroup"
        controlName="frequency"
        [items]="frequencies"
        [displayValue]="displayFrequencyFn">
    </app-select>

    <mat-label [isRequired]="iterations">{{ "manual_call_form_iteration_quantity" | locale }}</mat-label>
    <mat-form-field>
        <input
            matInput
            type="number"
            formControlName="iterations"
            [placeholder]="'manual_call_form_iteration_quantity_placeholder' | locale"
        />
        <mat-error>{{ iterations | errorMessage }}</mat-error>
    </mat-form-field>

    <span class="clarification">{{ "manual_call_form_iterations_clarification" | locale }}</span>
    <span class="finish-date">{{ "manual_call_form_finish_date_message" | locale }} {{ finishDate }}</span>
    <span class="validations" *ngIf="validationsMessage">{{ validationsMessage | locale }}</span>

    <button
        mat-flat-button
        [disabled]="disableControls"
        (click)="submit()"
        color="primary">
        {{ "manual_call_form_send" | locale }}
    </button>
</form>
