import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  icon: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string, type: 'error' | 'success' }
  ) {
    if(data.type === 'error') {
      this.icon = 'red-cross';
    } else {
      this.icon = 'green-check';
    }
  }

}
