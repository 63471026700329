import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Doctor } from '../models/doctor.model';
import { DoctorFileData } from '../models/doctor-file-data.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  baseApiURL = '/doctor';
  canPrescribeMedication: boolean;
  canMakeMedicalOrderInstruction: boolean;

  constructor(
    private requestService: RequestService,
  ) { }

  getMe(): Promise<Doctor> {
    return this.requestService.get('/me');
  }

  getFileData(doctorId: string): Promise<{[key: string]: DoctorFileData}> {
    return this.requestService.get(`${this.baseApiURL}/${doctorId}/fileData`);
  }

  createFileData(doctorId: string, formData: FormData): Promise<DoctorFileData> {
    return this.requestService.post(`${this.baseApiURL}/${doctorId}/fileData`, formData);
  }

  updateFileData(doctorFileDataId: string, formData: FormData): Promise<DoctorFileData> {
    return this.requestService.put(`${this.baseApiURL}/fileData/${doctorFileDataId}`, formData);
  }

  sendNotificationSubmittedRequestedDocumentation(doctorId: string) {
    return this.requestService.post(`/email/requestedDocumentation/doctor/${doctorId}`, {});
  }

  async getCanPrescribeMedication(doctorId: string, callId: string): Promise<boolean> {
    if(_.isUndefined(this.canPrescribeMedication)) {
      this.canPrescribeMedication = await this.requestService.get(`${this.baseApiURL}/${doctorId}/enrollment/canPrescribeMedication`, { call: callId });
    }
    return this.canPrescribeMedication;
  }

  async getCanMakeMedicalOrderInstruction(doctorId: string): Promise<boolean> {
    if(_.isUndefined(this.canMakeMedicalOrderInstruction)) {
      this.canMakeMedicalOrderInstruction = await this.requestService.get(`${this.baseApiURL}/${doctorId}/enrollment/canMakeMedicalOrderInstruction`);
    }
    return this.canMakeMedicalOrderInstruction;
  }
}
