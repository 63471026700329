import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DoctorComponent } from './call-form/doctor/doctor.component';
import { MessageComponent } from './message/message.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { AppointmentCancelComponent } from './appointment/appointment-cancel/appointment-cancel.component';
import { AppPaths } from './enums/app-paths.enum';

const routes: Routes = [
  { path: AppPaths.CALL_FORM, component: DoctorComponent },
  { path: AppPaths.DOCUMENTATION_FORM, component: DocumentationComponent },
  { path: AppPaths.MESSAGE, component: MessageComponent },
  { path: AppPaths.APPOINTMENT, children: [
    { path: AppPaths.CANCEL_FORM, component: AppointmentCancelComponent }
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
