import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CallService } from 'src/app/services/call.service';
import { NotificationsService } from 'src/app/services/notifications.service'
import * as _ from 'lodash';
import { FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { MedicalOrder } from 'src/app/models/medical-order.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { MedicalOrderFormComponent } from 'src/app/call-form/medical-order-form/medical-order-form.component';
import { Call } from 'src/app/models/call.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { DoctorService } from 'src/app/services/doctor.service';
import { Practice } from 'src/app/models/practice.model';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-medical-order',
  templateUrl: './medical-order.component.html',
  styleUrls: ['./medical-order.component.css']
})
export class MedicalOrderComponent implements OnInit {
  @Input()
  call: Call;

  @Output()
  nextStepEvent = new EventEmitter<void>();

  @ViewChild(MedicalOrderFormComponent)
  medicalOrderFormComponent: MedicalOrderFormComponent;

  medicalOrderFormHasFilledFields = false;
  patientHasRepeatedAbsenteeism = false;
  formGroup: FormGroup;
  resetSubject = new Subject<void>;
  enableMedicalOrderDescription: boolean;
  enableRestIndicationSubject = new Subject<boolean>();
  restIndicationSubmitted = false;

  practicesToOmitFromListSubject = new BehaviorSubject<string[]>([]);
  practicesToOmitFromList: string[] = [];

  constructor(
    private callService: CallService,
    private notificationsService: NotificationsService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private doctorService: DoctorService,
    private localeService: LocaleService,
  ) { }

  async ngOnInit(): Promise<void> {
    const [canPrescribeMedication] = await Promise.all([
      this.doctorService.getCanPrescribeMedication(this.call.doctor.id, this.call.id),
      this.getPatientHasRepeatedAbsenteeism()
    ]);

    this.enableMedicalOrderDescription = canPrescribeMedication;

    if(this.call.provider.usesPractices) {
      this.call.medicalOrders?.forEach((mo) => {
        mo.practices?.forEach(practice => {
          this.removePracticeFromList(practice.id);
        })
      })
    }

    this.buildForm();
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      medicalOrders: this.formBuilder.array(this.fillMedicalOrderForms()),
    });
  }

  fillMedicalOrderForms(): FormGroup[] {
    const formGroups: FormGroup[] = [];
    this.call.medicalOrders?.forEach((medicalOrder: MedicalOrder) =>
      formGroups.push(this.setMedicalOrderFormArray(medicalOrder))
    );
    return formGroups;
  }

  private setMedicalOrderFormArray(medicalOrder: MedicalOrder): FormGroup {
    const disable = !!medicalOrder.id;
    const formGroup = this.formBuilder.group({
      id: medicalOrder.id,
      description: {value: medicalOrder.description || '', disabled: disable},
      instructions: {value: medicalOrder.instructions || '', disabled: disable},
      restIndicationFormGroup: this.formBuilder.group({
        restIndication: medicalOrder.restIndication,
        restTime: null,
      }),
      practice: { value: '', disabled: disable },
      selectedPractices: this.formBuilder.array([]),
    }, { validators: [this.medicalOrderValidator()] });

    if(!_.isEmpty(medicalOrder.practices)) {
      medicalOrder.practices.forEach(practice => {
        (formGroup.get('selectedPractices') as FormArray).push(this.formBuilder.group(practice));
      })
    }

    return formGroup;
  }

  medicalOrderValidator(): ValidatorFn {
    return (group: FormGroup) => {
      if(group.controls?.description?.value || group.controls?.instructions?.value || group.controls?.selectedPractices?.value) return null;
      return {required: true};
    };
  }

  getPatientHasRepeatedAbsenteeism(): void {
    this.callService.patientHasRepeatedAbsenteeism(this.call.patient.id).then(value => {
      this.patientHasRepeatedAbsenteeism = value;
    });
  }

  addMedicalOrder(medicalOrder: MedicalOrder): void {
    const hasAnyOrderRestIndication = this.medicalOrders.value.some(mo => mo.restIndicationFormGroup?.restIndication) || this.call.restIndication;

    if(medicalOrder.restIndication) {
      this.enableRestIndicationSubject.next(false);
    } else if(!hasAnyOrderRestIndication && !this.restIndicationSubmitted) {
      this.enableRestIndicationSubject.next(true);
    }

    this.medicalOrders.push(this.setMedicalOrderFormArray(medicalOrder));
  }

  checkIfMedicalOrderFormHasFilledFields(): void {
    if(this.medicalOrderFormComponent) {
      const { description, instructions, selectedPractices } = this.medicalOrderFormComponent;
      this.medicalOrderFormHasFilledFields = !!(description?.value?.trim() || instructions?.value?.trim() || !_.isEmpty(selectedPractices.value));
    }
  }

  getUploadedMedicalOrderTitle(formGroup: FormGroup): string {
    const description = formGroup.get('description')?.value;
    const instructions = formGroup.get('instructions')?.value;
    const practices = formGroup.get('selectedPractices')?.value;

    if(!_.isEmpty(practices)) return this.localeService.getString("practices") + " (+)";
    return description?.trim().length > 0 ? description : (instructions?.trim().length > 0 ? instructions : '-');
  }

  onRemoveMedicalOrder(index: number): void {
    this.matDialog.open(DialogComponent, {
      panelClass: 'dark-dialog',
      width: '450px',
      data: {
        title: 'default_warning_message',
        message: 'medical_order_delete',
        canCancel: true,
        actions: [
          {
            function: () => this.removeMedicalOrder(index),
            message: 'confirm'
          }
        ]
      },
    });
  }

  removeMedicalOrder(index: number): void {
    const { selectedPractices } = this.medicalOrderFormComponent;
    const hasCurrentOrderPractices = !_.isEmpty(selectedPractices.controls);

    if(
      (this.medicalOrders?.controls[index] as FormGroup)?.get('restIndicationFormGroup.restIndication')?.value &&
      !hasCurrentOrderPractices
    ) {
      this.enableRestIndicationSubject.next(true);
    }

    this.medicalOrders?.value[index].selectedPractices?.forEach(practice => {
      this.addPracticeToList(practice);
    });

    this.medicalOrders.removeAt(index);
  }

  getMedicalOrdersToCreate(): MedicalOrder[] {
    let medicalOrders: MedicalOrder[] = [];
    const rawMedicalOrders = this.medicalOrders.getRawValue();

    rawMedicalOrders.forEach(medicalOrder => {
      if(_.isEmpty(medicalOrder.id)) {
        medicalOrder.restIndication = medicalOrder.restIndicationFormGroup?.restIndication;
        medicalOrder.practices = medicalOrder.selectedPractices;
        delete medicalOrder.restIndicationFormGroup;
        delete medicalOrder.selectedPractices;
        medicalOrders.push(medicalOrder);
      }
    });

    const { description, instructions, restIndication, selectedPractices } = this.medicalOrderFormComponent;

    if(description.value || instructions.value || !_.isEmpty(selectedPractices.value))
      medicalOrders = [
        ...medicalOrders,
        {
          description: description.value?.trim(),
          instructions: instructions.value?.trim(),
          restIndication: restIndication.value,
          practices: selectedPractices.value
        }
      ];

    return this.removeVoidKeys(medicalOrders);
  }

  removeVoidKeys(medicalOrders: MedicalOrder[]): MedicalOrder[] {
    medicalOrders.forEach((medicalOrder) =>
      Object.keys(medicalOrder).forEach((key) =>
        (medicalOrder[key] == null || medicalOrder[key]?.length === 0 || medicalOrder[key] === false) ? delete medicalOrder[key] : {}
      )
    );
    return medicalOrders.filter(medicalOrder => !_.isEmpty(medicalOrder));
  }

  removePracticeFromList(practiceId: string) {
    this.practicesToOmitFromList.push(practiceId);
    this.practicesToOmitFromListSubject.next(this.practicesToOmitFromList);
  }

  onSelectPractice(practiceId: string) {
    this.removePracticeFromList(practiceId);
  }

  addPracticeToList(practice: Practice) {
    this.practicesToOmitFromList = this.practicesToOmitFromList.filter(practiceId => practiceId !== practice.id);
    this.practicesToOmitFromListSubject.next(this.practicesToOmitFromList);
  }

  onRemovePractice($event: {practice: Practice, isPracticesListEmpty: boolean}) {
    const {practice, isPracticesListEmpty} = $event;
    if(isPracticesListEmpty) {
      const medicalOrders = this.getMedicalOrdersToCreate();
      if(!medicalOrders?.some(medicalOrder => medicalOrder.restIndication) && !this.call.restIndication) {
        this.enableRestIndicationSubject.next(true);
      }
    }
    this.addPracticeToList(practice);
  }

  skipStep(): void {
    this.nextStepEvent.emit();
  }

  submit(): void {
    const medicalOrderData = {
      call: this.call.id,
      medicalOrders: this.getMedicalOrdersToCreate(),
    }

    medicalOrderData.medicalOrders?.forEach(mo => {
      if(mo.practices) mo.practices = _.map(mo.practices, practice => practice.id) as any[];
    });

    const hasAnyRestIndication = medicalOrderData.medicalOrders.some(mo => mo.restIndication);
    if(hasAnyRestIndication) {
      this.restIndicationSubmitted = true;
      this.enableRestIndicationSubject.next(false);
    }

    this.callService.createMedicalOrder(medicalOrderData).then(createdMedicalOrders => {
      // Remove new medical orders from array
      _.forEachRight(this.medicalOrders.controls, (formGroup: FormGroup, index) => {
        if(!formGroup.controls.id?.value) this.medicalOrders.removeAt(index);
      })

      createdMedicalOrders?.forEach(medicalOrder => {
        this.call.medicalOrders.push(medicalOrder);
        // Push created medical orders into array
        this.medicalOrders.push(this.setMedicalOrderFormArray(medicalOrder));
      });

      // Reset form
      this.resetSubject.next();

      // Go to next step
      this.nextStepEvent.emit();
    }).catch((err) => {
      this.notificationsService.openErrorDialog({
        title: "default_warning_message",
        message: "order_error",
        actions: [{
          function: () => undefined,
          message: "close"
        }],
        error: err.error || err
      }, false)
    })
  }

  get medicalOrders(): FormArray { return this.formGroup.get('medicalOrders') as FormArray }
}
