<ng-container [formGroup]="formGroup">
    <mat-checkbox
        *ngIf="displayRequiresPrescription"
        class="red"
        formControlName="requiresPrescription">
        {{ "diagnosis_prescription_another_country" | locale }}
    </mat-checkbox>
</ng-container>

<app-item
    *ngIf="supportsMonitoring"
    [itemText]="'monitoring' | locale"
    [south]="true"
    [collapsable]="true"
    [itemDetailTemplate]="monitoringCollapsable"
    [openCollapsable]="isLastIteration">
</app-item>

<ng-template #monitoringCollapsable let-open="openCollapsable">
    <div class="collapsable" [hidden]="!open">
        <form [formGroup]="monitoringFormGroup">
            <mat-radio-group
                formControlName="otherDoctor"
                color="primary">
                <mat-radio-button
                    [disabled]="disableOtherDoctorMonitoring"
                    [value]="true">
                    {{ "monitoring_other_doctor" | locale }}
                    <mat-error *ngIf="patient.onMonitoring">
                        {{ "error_patient_on_monitoring" | locale }} <!-- The patient is already on monitoring. -->
                    </mat-error>
                </mat-radio-button>

                <mat-radio-button
                    [disabled]="disableMonitoringForm"
                    [value]="false">
                    {{ "monitoring_show_form" | locale }}
                    <mat-error *ngIf="hasActiveTask">
                        {{ "error_monitoring_active_task" | locale }} <!-- The patient has an ongoing monitoring. -->
                    </mat-error>
                    <mat-error *ngIf="patient.onMonitoring && patient.doctorAssigned && patient.doctorAssigned != call.doctor.id">
                        {{ "error_monitoring_another_doctor" | locale }} <!-- The patient is in monitoring with another doctor. -->
                    </mat-error>
                    <mat-error *ngIf="!call.doctor.canMonitoring && patient.doctorAssigned && patient.doctorAssigned != call.doctor.id">
                        {{ "error_can_not_monitoring" | locale }} <!-- You are not able to initiate monitoring. -->
                    </mat-error>
                </mat-radio-button>
                <mat-error *ngIf="patient.needsMonitoring || patient.needsValidation">
                    {{ "error_patient_needs_monitoring" | locale }} <!-- Monitoring was already required for this patient. -->
                </mat-error>
            </mat-radio-group>
            <p class="error" *ngIf="isLastIteration">{{ 'last_monitoring_message' | locale }}</p>

            <app-monitoring
                [hidden]="otherDoctor.value !== false"
                [call]="call"
                [disableControls]="disableMonitoringButton"
                (monitoringEnded)="successMonitoring()">
            </app-monitoring>

            <button
                *ngIf="otherDoctor.value"
                mat-flat-button
                [disabled]="disableMonitoringButton"
                (click)="updatePatientMonitoring()"
                color="primary">
                {{ "manual_call_form_send" | locale }}
            </button>
        </form>
    </div>
</ng-template>

<form [formGroup]="formGroup">
    <div class="rate-container">
        <span>{{ "rate_stars" | locale }}</span>
        <div class="rate">
            <button
                *ngFor="let ratingId of starCount | fillArray; index as i"
                mat-icon-button
                [disabled]="this.call.doctorStars > 0"
                color="primary"
                (click)="onRateClick($event, i + 1)">
                <mat-icon [svgIcon]="doctorStars.value > i ? 'star-fill' : 'star-outline'"></mat-icon>
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <mat-label [isRequired]="doctorText">{{ 'rate_comments' | locale }}</mat-label>
    <mat-form-field class="textarea">
        <textarea
            matInput
            rows="6"
            [placeholder]="'rate_comments_placeholder' | locale"
            formControlName="doctorText">
        </textarea>
        <mat-error>{{ doctorText | errorMessage }}</mat-error>
    </mat-form-field>

    <mat-label [isRequired]="doctorDuration">{{ 'call_duration' | locale }}</mat-label>
    <mat-form-field class="duration-form-field">
        <input
            matInput
            type="text"
            formControlName="doctorDuration"
            placeholder="mm:ss"
            autocomplete="off"
        />
        <mat-error>{{ doctorDuration | errorMessage }}</mat-error>
    </mat-form-field>

    <div class="actions">
        <button
            mat-flat-button
            (click)="submit()"
            color="primary"
            [disabled]="
                formGroup.invalid ||
                (call?.status === CallStatus.ENDED_MANUAL && !call.provider.requiresPrescriptionForAnotherCountry ? true : call.requiresPrescription)"
            >
            {{ "rate_end" | locale }}
        </button>
    </div>
</form>
