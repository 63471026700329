<form [formGroup]="formGroup" #formGroupDirective="ngForm">
    <app-select
        label="documentation_enrollment_country"
        [form]="formGroup"
        controlName="country"
        placeholder="documentation_enrollment_country_placeholder"
        [items]="countries">
    </app-select>

    <app-select
        label="documentation_enrollment_province"
        [form]="formGroup"
        controlName="province"
        placeholder="documentation_enrollment_province_placeholder"
        [items]="filteredEnrollmentProvinces"
        [displayValue]="displayProvince">
    </app-select>

    <app-select
        label="documentation_enrollment_type"
        [form]="formGroup"
        controlName="type"
        placeholder="documentation_enrollment_type_placeholder"
        [items]="filteredEnrollmentTypes">
    </app-select>

    <mat-label [isRequired]="number">{{ 'documentation_enrollment_code' | locale }}</mat-label>
    <mat-form-field>
        <input
            matInput
            type="number"
            formControlName="number"
            [placeholder]="'documentation_enrollment_code_placeholder' | locale"
        />
        <mat-error>{{ number | errorMessage }}</mat-error>
    </mat-form-field>

    <app-datepicker
        label="documentation_enrollment_date"
        [form]="formGroup"
        controlName="expirationDate"
        placeholder="documentation_enrollment_date_placeholder">
    </app-datepicker>

    <mat-label [isRequired]="file">{{ 'documentation_enrollment_file' | locale }}</mat-label>
    <app-file-input
        [files]="fileUrl ? [ fileUrl! ] : []"
        [supportedExtensions]="supportedFileExtensions"
        (uploadFileEvent)="onUploadFile($event)"
        (removeFileEvent)="onRemoveFile()">
    </app-file-input>

    <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="submit()">
        {{ (enrollment ? 'default_save' : 'documentation_enrollment_add') | locale }}
    </button>
</form>
