import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Enrollment } from '../models/enrollment.model';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  token: string;
  callId: string;
  doctorId?: string;
  private enrollmentsSubject = new BehaviorSubject<Partial<Enrollment>[]>([]);
  diagnosisCompleteSubject = new BehaviorSubject(false);
  apiUrlSubject = new BehaviorSubject('');

  setToken(token: string) {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }

  setCall(callId: string): void {
    this.callId = callId;
  }

  getCall(): string {
    return this.callId;
  }

  setDoctorId(doctorId: string): void {
    this.doctorId = doctorId;
  }

  getDoctorId(): string {
    return this.doctorId;
  }

  set enrollments(enrollments: Partial<Enrollment>[]) {
    this.enrollmentsSubject.next(enrollments);
  }

  get enrollments(): Partial<Enrollment>[] {
    return this.enrollmentsSubject.value;
  }

  get enrollments$(): Observable<Partial<Enrollment>[]> {
    return this.enrollmentsSubject.asObservable();
  }

  set diagnosisComplete(value: boolean) {
    this.diagnosisCompleteSubject.next(value);
  }

  get diagnosisComplete(): boolean {
    return this.diagnosisCompleteSubject.value;
  }

  get diagnosisComplete$(): Observable<boolean> {
    return this.diagnosisCompleteSubject.asObservable();
  }

  set apiUrl(apiUrl: string) {
    this.apiUrlSubject.next(apiUrl);
  }

  get apiUrl(): string {
    return this.apiUrlSubject.value;
  }

  get apiUrl$(): Observable<string> {
    return this.apiUrlSubject.asObservable();
  }
}
