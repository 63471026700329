import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { Call } from 'src/app/models/call.model';
import { DiagnosisService } from 'src/app/services/diagnosis.service';
import { MedicalHistoryService } from 'src/app/services/medical-history.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-call-information',
  templateUrl: './call-information.component.html',
  styleUrls: ['./call-information.component.css']
})
export class CallInformationComponent implements OnInit {
  @Input() call: Call;

  @Input() previousRecords?: string[];

  patientBirthDate?: moment.Moment;
  medicalHistory: string;
  diagnosis: string;

  constructor(
    private medicalHistoryService: MedicalHistoryService,
    private diagnosisService: DiagnosisService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.initializeVariables();

    this.sharedDataService.diagnosisComplete$.pipe(debounceTime(0)).subscribe(() => {
      this.initializeVariables();
    });
  }

  initializeVariables(): void {
    this.patientBirthDate = this.call?.patient?.birthDate ? moment.unix(this.call.patient.birthDate) : null;
    this.medicalHistory = this.medicalHistoryService.getMedicalHistory(this.call?.records || this.previousRecords);
    this.diagnosis = this.diagnosisService.getByCode(this.call?.diagnosis?.diagnosis);
  }
}
