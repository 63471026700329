import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Country } from 'src/app/models/country.model';
import { CountryService } from 'src/app/services/country.service';
import { ToastService } from 'src/app/services/toast.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { HttpClient } from '@angular/common/http';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { Enrollment } from 'src/app/models/enrollment.model';
import { MatDialog } from '@angular/material/dialog';
import { EnrollmentDialogComponent } from '../enrollment-dialog/enrollment-dialog.component';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { EnrollmentProvince } from 'src/app/models/enrollment-province.model';
import { EnrollmentType } from 'src/app/models/enrollment-type.model';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.css']
})
export class EnrollmentComponent implements OnInit {
  @Input()
  formGroup!: FormGroup;

  countries!: Country[];
  enrollmentProvinces!: EnrollmentProvince[];
  enrollmentTypes!: EnrollmentType[];

  displayedColumns = ['type', 'enrollment', 'country', 'expirationDate', 'action'];
  dataSource = new MatTableDataSource();

  resetFormSubject = new Subject<void>();

  constructor(
    private countryService: CountryService,
    private httpClient: HttpClient,
    private sharedDataService: SharedDataService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private enrollmentService: EnrollmentService,
  ) { }

  async ngOnInit(): Promise<void> {
    let enrollments: Enrollment[];
    [this.countries, this.enrollmentProvinces, this.enrollmentTypes, enrollments] = await Promise.all([
      this.countryService.get(),
      this.enrollmentService.getProvinces(),
      this.enrollmentService.getTypes(),
      this.enrollmentService.getByDoctor(),
    ]);

    if(enrollments) this.sharedDataService.enrollments = enrollments;

    const provinces = await this.httpClient.get<any[]>('assets/data/enrollment_provinces.json').toPromise();
    this.enrollmentProvinces.forEach((enrollmentProvince: any) => {
      const province = provinces[enrollmentProvince.name] ? `${enrollmentProvince.name} - ${provinces[enrollmentProvince.name]}` : enrollmentProvince.name;
      enrollmentProvince.province = province;
    })

    this.sharedDataService.enrollments$.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  async submit(): Promise<void> {
    const newEnrollment = {
      doctor: this.sharedDataService.getDoctorId(),
      country: this.country.value,
      enrollmentProv: this.province.value,
      enrollmentType: this.type.value,
      number: this.number.value,
      ...(this.expirationDate.value && {expirationDate: this.expirationDate.value}),
      ...(this.file.value && {fileS3Key: this.file.value}),
    }

    if(!_.isEmpty(this.enrollmentService.checkDuplicatedEnrollments(newEnrollment))) {
      this.toastService.openErrorToast("documentation_enrollment_submit_error_duplicated");
      return;
    }

    this.sharedDataService.enrollments = [...this.sharedDataService.enrollments, newEnrollment];

    this.resetFormSubject.next();
  }

  openEditEnrollmentDialog(enrollment: Enrollment): void {
    this.dialog.open(EnrollmentDialogComponent, {
      data: {
        enrollmentProvinces: this.enrollmentProvinces,
        enrollmentTypes: this.enrollmentTypes,
        countries: this.countries,
        enrollment,
      },
      width: '500px',
    }).afterClosed().subscribe(async (value: Enrollment) => {
      if(!value) return;

      const auxEnrollments = this.sharedDataService.enrollments;
      const index = _.findIndex(this.sharedDataService.enrollments, (el) => el.id === value.id);
      auxEnrollments[index] = value;
      this.sharedDataService.enrollments = auxEnrollments;
    })
  }

  deleteEnrollment(enrollment: Partial<Enrollment>): void {
    this.sharedDataService.enrollments = _.filter(this.sharedDataService.enrollments, element => !_.isEqual(element, enrollment));
  }

  openFile(url: string | File): void {
    if(_.isString(url)) window.open(url);
    else window.open(URL.createObjectURL(url));
  }

  get country(): FormControl { return this.formGroup.get('country') as FormControl }
  get province(): FormControl { return this.formGroup.get('province') as FormControl }
  get type(): FormControl { return this.formGroup.get('type') as FormControl }
  get number(): FormControl { return this.formGroup.get('number') as FormControl }
  get expirationDate(): FormControl { return this.formGroup.get('expirationDate') as FormControl }
  get file(): FormControl { return this.formGroup.get('file') as FormControl }
}
