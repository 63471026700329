import { Component } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent {
  logo!: string;
  apiUrl: string;

  constructor(
    private localeService: LocaleService,
    private sharedDataService: SharedDataService,
  ) {
    this.logo = this.localeService.getLadHeader();
    this.apiUrl = this.sharedDataService.apiUrl;

    this.sharedDataService.apiUrl$.subscribe(data => {
      this.apiUrl = data;
    });
  }

}
