import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// PIPES
import { LocalePipe } from './locale.pipe';
import { LocalizedNamePipe } from './localized-name.pipe';
import { IsRequiredDirective } from './shared/directives/is-required.directive';
import { ErrorMessagePipe } from './shared/pipes/error-message.pipe';
import { FillArrayPipe } from './shared/pipes/fill-array.pipe';
import { MomentDatePipe } from './shared/pipes/moment-date.pipe';
import { FileSizePipe } from './shared/pipes/file-size.pipe';

//COMPONENTS
import { DoctorComponent } from './call-form/doctor/doctor.component';
import { DiagnosisComponent } from './call-form/diagnosis/diagnosis.component';
import { PrescriptionComponent } from './call-form/prescription/prescription.component';
import { MedicalOrderComponent } from './call-form/medical-order/medical-order.component';
import { RateComponent } from './call-form/rate/rate.component';
import { AppComponent } from './app.component';
import { LoaderInterceptor } from './loader.interceptor';
import { MonitoringComponent } from './call-form/monitoring/monitoring.component';
import { MessageComponent } from './message/message.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { EnrollmentComponent } from './documentation/components/enrollment/enrollment.component';
import { EnrollmentFormComponent } from './documentation/components/enrollment-form/enrollment-form.component';
import { EnrollmentDialogComponent } from './documentation/components/enrollment-dialog/enrollment-dialog.component';
import { FileInputComponent } from './shared/file-input/file-input.component';
import { SelectComponent } from './shared/components/select/select.component';
import { DatepickerComponent } from './shared/components/datepicker/datepicker.component';
import { ToastComponent } from './shared/components/toast/toast.component';
import { ToolbarComponent } from './shared/components/toolbar/toolbar.component';
import { AppointmentCancelComponent } from './appointment/appointment-cancel/appointment-cancel.component';
import { AutocompleteComponent } from './shared/components/autocomplete/autocomplete.component';
import { CallInformationComponent } from './call-form/call-information/call-information.component';
import { DefaultTemplateComponent } from './call-form/default-template/default-template.component';
import { MedicineFormComponent } from './call-form/medicine-form/medicine-form.component';
import { ItemComponent } from './shared/components/item/item.component';
import { MedicalOrderFormComponent } from './call-form/medical-order-form/medical-order-form.component';
import { MedicalRecordsComponent } from './call-form/medical-records/medical-records.component';
import { MedicalRecordComponent } from './call-form/medical-record/medical-record.component';
import { MedicalExamsComponent } from './call-form/medical-exams/medical-exams.component';
import { MedicalExamDialogComponent } from './call-form/medical-exams/components/medical-exam-dialog/medical-exam-dialog.component';

//SERVICES
import { LocaleService } from './services/locale.service';

//MATERIAL
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { DateAdapter, MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { CustomDateAdapter } from './utils/custom-date-adapter';


export function setupTranslateFactory(localeService: LocaleService) {
  const locale = window.navigator.language;
  return () => localeService.use(locale.toLowerCase());
}

const CUSTOM_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY' // this is how the date will be parsed from input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how the date will get displayed on the input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    DoctorComponent,
    DiagnosisComponent,
    PrescriptionComponent,
    MedicalOrderComponent,
    RateComponent,
    LocalePipe,
    MonitoringComponent,
    MessageComponent,
    DialogComponent,
    LocalizedNamePipe,
    DocumentationComponent,
    EnrollmentComponent,
    EnrollmentFormComponent,
    EnrollmentDialogComponent,
    FileInputComponent,
    AppointmentCancelComponent,
    SelectComponent,
    IsRequiredDirective,
    ErrorMessagePipe,
    DatepickerComponent,
    ToastComponent,
    ToolbarComponent,
    AppointmentCancelComponent,
    AutocompleteComponent,
    CallInformationComponent,
    DefaultTemplateComponent,
    MedicineFormComponent,
    ItemComponent,
    MedicalOrderFormComponent,
    FillArrayPipe,
    MedicalRecordsComponent,
    MedicalRecordComponent,
    MomentDatePipe,
    MedicalExamsComponent,
    FileSizePipe,
    MedicalExamDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatGridListModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    MatRadioModule,
    MatTableModule,
    MatSnackBarModule,
    ScrollingModule,
    MatDividerModule,
    PdfViewerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ LocaleService ],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    LocalizedNamePipe,
    LocalePipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {closeOnNavigation: true, restoreFocus: false} },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
